import React from "react";
import Layout from "../general/Layout";
import CheckoutComp from "../../components/orders/CheckoutComp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { party, isError } = useSelector((store) => store.auth);
  // const { price, agreedToTerms } = useSelector((store) => store.order);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/clients/login");
    } else if (party && party.type !== "client") {
      // note (1)
      navigate("/dashboard");
    }
  }, [party, navigate, isError]);

  return (
    <>
      {/* {price && agreedToTerms ? ( */}
      <Layout>
        <CheckoutComp></CheckoutComp>
      </Layout>
      {/* ) : (
        <Navigate to="/quote" />
      )} */}
    </>
  );
};

export default Checkout;
