import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import InfoIconModal from "../general/InfoIconModal";
// import BusinessIconModal from "../general/BusinessIconModal";
import {
  setEmail,
  setPassword,
  setConfPassword,
  setVerificationCode,
  setVerifCodeSent,
  setVerifiedEmail,
} from "../../features/clientRegSlice";
// import { getToken } from "../../features/tokenSlice";

const UpdateClientPassForm = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  const navigate = useNavigate();

  const { email, password, confPassword } = useSelector(
    (state) => state.regClient
  );

  // Password validation function
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 10) {
      errors.push("Password must be at least 10 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    return errors;
  };

  // frontend fields validation for completeness
  const validateFields = () => {
    const errors = {};
    if (!password) {
      errors.password = "Password is required";
    } else {
      const passwordErrors = validatePassword(password);
      if (passwordErrors.length > 0) {
        errors.password = passwordErrors.join(" ");
      }
    }
    if (!confPassword) {
      errors.confPassword = "Confirm Password is required";
    } else if (confPassword !== password) {
      errors.confPassword = "Passwords do not match";
    }

    if (!password || !confPassword) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // this returns the keys of the errors object
  // remember each object has a key and a value
  // keys are name, cellphone, etc. If there are errors it returns false

  const saveClient = async (e) => {
    e.preventDefault();
    // letting this stay here causes errors
    setIsLoading(true);
    // validate the completeness of the fields
    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.patch("/api/clients/updatepassword", {
        email: email,
        password: password,
        confPassword: confPassword,
      });
      setMsg(response.data.msg);
      alert(response.data.msg);
      // Use a callback to ensure clearForm is called after navigation completes
      navigate("/clients/login", { state: {}, replace: true }, () => {
        clearForm();
      });
      //   navigate("/clients/verify") // after receiving client verification email
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
        // alert(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    dispatch(setEmail(""));
    dispatch(setPassword(""));
    dispatch(setConfPassword(""));
    dispatch(setVerificationCode(null));
    dispatch(setVerifCodeSent(false));
    dispatch(setVerifiedEmail(false));
    // this is because it causes AddClientForm to navigate to "/clients/register" to to conditional in AddClient
    // causing redirect issues when navigate to "/clients/login" should occur after submitting form data
  };

  return (
    <div>
      <section className="section">
        <div className="column">
          <div>
            <h1 className="title has-text-centered section-header">
              Customers
            </h1>
            <div className="columns is-centered">
              <div className="column is-8">
                <div className="card box">
                  <p className="card-header-title is-centered blue-font-color is-size-4">
                    New Password
                  </p>
                  <div className="card-content">
                    <div className="content">
                      <form>
                        <div className="field">
                          <label className="label blue-font-color">
                            New Password
                          </label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.password ? "is-danger" : ""
                              }`}
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) =>
                                dispatch(setPassword(e.target.value))
                              }
                            ></input>
                            {/* <InfoIconModal></InfoIconModal> */}
                            <button
                              type="button"
                              className="button is-green is-small is-primary mt-1"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "👁️" : "👁️‍🗨️"}
                            </button>
                          </div>
                          {errors.password && (
                            <p className="help is-danger">{errors.password}</p>
                          )}
                        </div>

                        <div className="field">
                          <label className="label blue-font-color">
                            Confirm Password
                          </label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.confPassword ? "is-danger" : ""
                              }`}
                              type={showPassword ? "text" : "password"}
                              value={confPassword}
                              onChange={(e) =>
                                dispatch(setConfPassword(e.target.value))
                              }
                            ></input>
                            <button
                              type="button"
                              className="button is-green is-small is-primary mt-1"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "👁️" : "👁️‍🗨️"}
                            </button>
                          </div>
                          {errors.confPassword && (
                            <p className="help is-danger">
                              {errors.confPassword}
                            </p>
                          )}
                        </div>

                        <p className="has-text-centered has-text-weight-bold">
                          {msg}
                        </p>

                        {/**frontend completeness validation oeverall message */}
                        {overallError && (
                          <p className="has-text-centered has-text-danger">
                            {overallError}
                          </p>
                        )}

                        <div className="field">
                          <div className="control">
                            <button
                              disabled={isLoading}
                              onClick={saveClient}
                              className="button blue-background-color has-text-white is-fullwidth"
                            >
                              {isLoading ? "Loading..." : "Submit"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpdateClientPassForm;
