import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Rating from "react-rating-stars-component";
import richardM from "../../assets/richardM.png";
import idPhoto from "../../assets/undraw_Pic_profile.png";

const ReviewSection = () => {
  // Dummy data for reviews
  const reviews = [
    {
      name: "Richard M Printers",
      content:
        '"Our business has been home-based for some time, and we were worried about moving into an office with all the business equipment. Instalorry assistants were very professional and handled our business equipment with the utmost care."',
      rating: 5,
      image: richardM,
    },
    {
      name: "Matthew Hugo",
      content:
        '"These guys are amazing. They took care of my furniture, were very patient and friendly. I will definitely use them on my next move."',
      rating: 5,
    },
    {
      name: "Lesedi Nkwe",
      content:
        '"Absolutely brilliant, bravo to instaLorry and their professional staff. I never imagined that moving between homes can be this easy."',
      rating: 5,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : reviews.length - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < reviews.length - 1 ? prevIndex + 1 : 0
    );
  };

  const renderImage = () => {
    const imageUrl = reviews[currentIndex].image;

    if (imageUrl) {
      return (
        <figure className="is-rounded">
          <img
            src={imageUrl}
            // alt={`${reviews[currentIndex].name}'s Photo`}
            alt=""
            className="review-image"
            style={{ maxHeight: "50px", margin: "0 auto" }}
          />
        </figure>
      );
    } else {
      return (
        <figure className="is-rounded">
          <img
            src={idPhoto}
            // alt={`${reviews[currentIndex].name}'s Photo`}
            alt=""
            className="review-image"
            style={{ maxHeight: "100px", margin: "0 auto" }}
          />
        </figure>
      );
    }
  };

  return (
    <div className="reviews-container">
      <br />
      <div>
        <FaArrowLeft
          className="arrow is-pulled-left is-size-4"
          onClick={handlePrevClick}
        />
        <FaArrowRight
          className="arrow is-pulled-right is-size-4"
          onClick={handleNextClick}
        />
      </div>
      <br />

      <div className="column">
        <div className="card">
          <p className="card-header-title is-centered is-size-4 firm-color mb-0">
            {reviews[currentIndex].name}
          </p>

          <div className="card-content has-text-centered mt-0">
            {renderImage()}

            <div className="is-flex is-justify-content-center">
              <Rating
                count={5}
                value={reviews[currentIndex].rating}
                size={24}
                activeColor="#ffd700"
              />
            </div>

            <p className="is-size-5">{reviews[currentIndex].content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
