import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import "bulma/css/bulma.css";
import "./assets/global.css"; // Import your global styles
import axios from "axios";

// axios.defaults.baseURL = "http://localhost:5500"; // define the api base once
// axios.defaults.baseURL = "https://c329-102-223-58-176.ngrok-free.app";
if (process.env.REACT_APP_ENVIRONMENT === "production") {
  axios.defaults.baseURL = "https://server.instalorry.co.za";
} else {
  axios.defaults.baseURL = "http://localhost:5500";
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
