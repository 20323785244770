import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";

const UpdateVehicleForm = () => {
  const [truckSize, setTruckSize] = useState("");
  const [licencePlate, setLicencePlate] = useState("");
  const [shape, setShape] = useState("");
  const [git, setGit] = useState("");
  const [status, setStatus] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  useEffect(() => {
    const getVehicleById = async () => {
      try {
        const response = await axios.get(`api/vehicles/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setTruckSize(response.data.truckSize);
        setLicencePlate(response.data.licencePlate);
        setShape(response.data.shape);
        setGit(response.data.git);
        setStatus(response.data.status);
        setOwnerId(response.data.ownerId);
        setDriverId(response.data.driverId);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getVehicleById();
  }, [id, token]);

  const updateVehicle = async (e) => {
    e.preventDefault();

    try {
      await axios.patch(
        `/api/vehicles/update/${id}`,
        {
          truckSize: truckSize,
          licencePlate: licencePlate,
          shape: shape,
          git: git,
          status: status,
          ownerId: ownerId,
          driverId: driverId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/vehicles/all");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div>
        <h1 className="title">Vehicle</h1>
        <h2 className="subtitle">Update Vehicle Information</h2>
        <div className="columns iscentered">
          <div className="column is-8">
            <div className="card-content">
              <div className="content">
                <form onSubmit={updateVehicle}>
                  <p>{msg}</p>

                  <div className="field">
                    <label className="label">Truck Size</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={truckSize}
                          onChange={(e) => setTruckSize(e.target.value)}
                        >
                          <option value="0.5ton">half ton</option>
                          <option value="1ton">1 ton</option>
                          <option value="1.5ton">1.5 ton</option>
                          <option value="3ton">3 ton</option>
                          <option value="8ton">8 ton</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Licence Plate</label>
                    <div className="control">
                      <input
                        className="input"
                        placeholder="abc123gp"
                        value={licencePlate}
                        onChange={(e) => setLicencePlate(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Shape of the Vehicle</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={shape}
                          onChange={(e) => setShape(e.target.value)}
                        >
                          <option value="">Please select</option>
                          <option value="open">Open</option>
                          <option value="closed">Closed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      Do you have GIT - Goods In Transit Insurance?
                    </label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={git}
                          onChange={(e) => setGit(e.target.value)}
                        >
                          <option value="">Please select</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Status</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="">Please select</option>
                          <option value="pending">Pending</option>
                          <option value="active">Active</option>
                          <option value="suspended">Suspended</option>
                          <option value="removed">Removed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Driver Id</label>
                    <div className="control">
                      <input
                        className="input"
                        value={driverId}
                        onChange={(e) => setDriverId(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Owner Id</label>
                    <div className="control">
                      <input
                        className="input"
                        value={ownerId}
                        onChange={(e) => setOwnerId(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        type="submit"
                        className="button is-success is-fullwidth"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVehicleForm;
