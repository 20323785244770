import React from "react";
import Layout from "../general/Layout";
import LoginFormUser from "../../components/users/LoginFormUser";

const LoginUser = () => {
  return (
    <Layout>
      <LoginFormUser></LoginFormUser>
    </Layout>
  );
};

export default LoginUser;
