import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import Payfast from "./Payfast";
import {
  setTruckSize,
  setOrderDate,
  setOrderTime,
  setPickup,
  setDestination,
  setKms,
  setFloors,
  setHelpers,
  setNotes,
  setBaseCost,
  setDistanceCost,
  setHelpersCost,
  setFloorsCost,
  setMidmonthDiscount,
  setRepeatDiscount,
  setPromotionDiscount,
  setPrice,
  setCommission,
  setNet,
  setPaymentType,
  setAgreedToTerms,
} from "../../features/orderSlice";
import { getToken } from "../../features/tokenSlice";

const CheckoutComp = () => {
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [selectedOption, setSelectedOption] = useState(null);

  const {
    truckSize,
    orderDate,
    orderTime,
    helpers,
    pickup,
    destination,
    floors,
    baseCost,
    distanceCost,
    helpersCost,
    floorsCost,
    midmonthDiscount,
    repeatDiscount,
    promotionDiscount,
    price,
    commission,
    net,
    notes,
    kms,
    paymentType,
  } = useSelector((store) => store.order);

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const handleOptionChange = (event) => {
    // setSelectedOption(event.target.value);
    dispatch(setPaymentType(event.target.value));
  };

  const clearForm = () => {
    // reset state so that quote form is clean
    dispatch(setTruckSize(""));
    dispatch(
      setPickup({ formatted_address: "", location: { lat: null, lng: null } })
    );
    dispatch(
      setDestination({
        formatted_address: "",
        location: { lat: null, lng: null },
      })
    );
    dispatch(setKms(0));
    dispatch(setOrderDate(""));
    dispatch(setOrderTime(""));
    dispatch(setHelpers(0));
    dispatch(setFloors(0));
    dispatch(setBaseCost(0));
    dispatch(setDistanceCost(0));
    dispatch(setHelpersCost(0));
    dispatch(setFloorsCost(0));
    dispatch(setMidmonthDiscount(0));
    dispatch(setRepeatDiscount(0));
    dispatch(setPromotionDiscount(0));
    dispatch(setPrice(0));
    dispatch(setCommission(0));
    dispatch(setNet(0));
    dispatch(setNotes(""));
    dispatch(setAgreedToTerms(""));
    dispatch(setPaymentType(null));
  };

  const bookMove = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "/api/orders/create",
        {
          truckSize: truckSize, // this is the data that we want to post
          // for name we attach the name from the state
          orderDate: orderDate,
          orderTime: orderTime,
          pickup: pickup.formatted_address,
          destination: destination.formatted_address,
          kms: kms,
          helpers: helpers,
          floors: floors,
          baseCost: baseCost,
          distanceCost: distanceCost,
          helpersCost: helpersCost,
          floorsCost: floorsCost,
          midmonthDiscount: midmonthDiscount,
          repeatDiscount: repeatDiscount,
          promotionDiscount: promotionDiscount,
          price: price,
          commission: commission,
          net: net,
          notes: notes,
          paymentType: paymentType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert(response.data.msg);

      // Use a callback to ensure clearForm is called after navigation completes
      navigate("/orders/all");
      window.scrollTo(0, 0);
    } catch (error) {
      if (error.response) {
        // setMsg(error.response.data.msg)    // if there is an error we will set it in the state
        // showAlert(error.response.data.msg, 3000);
        alert(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
      clearForm();
    }
  };

  const validateFields = () => {
    const errors = {};
    if (!paymentType) {
      errors.paymentType = "Please select the payment method";
    }
    if (!paymentType) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1 className="title has-text-centered section-header">
                Checkout
              </h1>

              {/* <p className="has-text-centered">{msg}</p> */}

              <h6 className="subtitle is-6 has-text-centered blue-font-color">
                This is the last step!
              </h6>
            </div>
          </div>
        </div>
      </section>

      <section className="section pb-4 pt-4">
        <div className="container">
          <div className="columns is-centered box">
            <div className="column is-7">
              <h5 className="subtitle has-text-weight-bold has-text-centered blue-font-color">
                Confirm Order Details
              </h5>
              <h6 className="subtitle is-6">
                The details of the move are as follows, please ensure this is
                correct otherwise click the 'Back' button at the bottom to make
                changes:
              </h6>
              <table className="table is-vertical">
                <tbody>
                  <tr>
                    <th>Date of move:</th>
                    <td>{orderDate}</td>
                  </tr>
                  <tr>
                    <th>Time of move:</th>
                    <td>{orderTime}</td>
                  </tr>
                  <tr>
                    <th>Truck size:</th>
                    <td>{truckSize}</td>
                  </tr>
                  <tr>
                    <th>Pickup Address:</th>
                    <td>{pickup.formatted_address}</td>
                  </tr>
                  <tr>
                    <th>Destination Address:</th>
                    <td>{destination.formatted_address}</td>
                  </tr>
                  <tr>
                    <th>Distance in Kilometers:</th>
                    <td>{kms}</td>
                  </tr>
                  <tr>
                    <th>Number of helpers:</th>
                    <td>{helpers}</td>
                  </tr>
                  <tr>
                    <th>Total floors (pickup plus destination):</th>
                    <td>{floors}</td>
                  </tr>
                  <tr>
                    <th>Price:</th>
                    <td>
                      <strong style={{ color: "green" }}>R{price}</strong>
                    </td>
                  </tr>
                  <tr>
                    <th>Additional Notes:</th>
                    <td>{notes}</td>
                  </tr>
                  <tr>
                    <th>Payment type:</th>
                    <td>{paymentType}</td>
                  </tr>
                </tbody>
              </table>

              <div className="field">
                <div className="control">
                  <div className="buttons is-centered">
                    <button
                      onClick={() => navigate("/orders/terms")}
                      type="button"
                      className={`button blue-background-color has-text-white is-rounded`}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section pb-4 pt-4">
        <div className="container">
          <div className="columns is-centered box">
            <div className="column is-7">
              <h5 className="subtitle has-text-weight-bold">
                Method of Payment:
              </h5>

              <form>
                <div className="field">
                  <div className="control">
                    <label className="radio pl-2">
                      <input
                        type="radio"
                        name="answer"
                        value="card"
                        checked={paymentType === "card"}
                        onChange={handleOptionChange}
                        disabled={true}
                      />
                      <span className="ml-2">
                        Card - Option available in the future
                      </span>
                    </label>

                    <label className="radio">
                      <input
                        type="radio"
                        name="answer"
                        value="cash"
                        // defaultChecked="true"
                        checked={paymentType === "cash"}
                        onChange={handleOptionChange}
                      />
                      <span className="ml-2">Cash - On date of move</span>
                    </label>
                  </div>
                  {errors.paymentType && (
                    <p className="help is-danger">{errors.paymentType}</p>
                  )}
                </div>

                {overallError && (
                  <div>
                    <p className="has-text-centered has-text-danger">
                      {overallError}
                    </p>
                  </div>
                )}

                <div className="field">
                  <div className="control">
                    <div className="buttons is-centered">
                      <button
                        type="submit"
                        onClick={bookMove}
                        className={`button blue-background-color has-text-white is-rounded`}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Book Order"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CheckoutComp;
