import React from "react";
import Navbar from "../../components/general/Navbar";
import Sidebar from "../../components/general/Sidebar";
import Footer from "../../components/general/Footer";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import FloatingWhatsApp from "../../components/general/FloatingWhatsapp";

const Layout = ({ children }) => {
  const { party } = useSelector((store) => store.auth);

  const isTouchDevice = useMediaQuery({ maxWidth: 1023 }); // max length for a touch devices

  return (
    <React.Fragment>
      <Navbar></Navbar>
      <div className="columns mt-6" style={{ minHeight: "100vh" }}>
        {" "}
        {/*Set display to flex*/}
        {party ? (
          <div className="column is-2">
            {" "}
            {/*Set flex properties*/}
            {/* Pass the burgerClass prop to Sidebar */}
            {isTouchDevice ? "" : <Sidebar></Sidebar>}
          </div>
        ) : null}
        <div className="column has-background-light content-column">
          {" "}
          {/**Set flex to take remaining space */}
          <main>{children}</main>
        </div>
      </div>
      <Footer />
      <FloatingWhatsApp></FloatingWhatsApp>
    </React.Fragment>
  );
};

export default Layout;
