import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import furniture from "../../assets/undraw_logistics.png";
import moving from "../../assets/undraw_houses.png";
import partnership from "../../assets/undraw_Agreement.png";
import QuoteComp from "../orders/QuoteComp";
import Welcome from "./Welcome";
import ContactForm from "./ContactForm";
import ReviewSection from "../drivers/ReviewSection";
// import LiveChat from "./LiveChat";
import { Link } from "react-router-dom";
import { resetDriverReg } from "../../features/driverRegSlice";

const HomeComp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetDriverReg());
  }, [dispatch]);
  // resetting the state from driver registration in the AddDriverForm here.
  // this is because resetting it the registration form was causing abnormal behaviour.
  // the reset would run when the navigation was not complete.

  return (
    <div>
      <section className="section">
        <div className="container">
          <Welcome></Welcome>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="title">
            <h1 className="title has-text-centered has-text-weight-bold">
              Our Services
            </h1>
          </div>

          <div className="columns has-text-centered">
            <div className="column">
              <div className="card">
                {/* <p className="card-header-title is-centered is-size-3 firm-color">
                  moving
                </p> */}
                <h1 className="card-header-title is-size-3 is-centered has-text-weight-normal">
                  Moving
                </h1>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={moving} alt="moving"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    household or corporate moves can be very stressful, our
                    experienced drivers and their assistants can make the
                    process very easy for you.
                  </p>
                </div>
                <footer className="card-footer blue-background-color">
                  <Link
                    to="/services"
                    className="card-footer-item has-text-white"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Click here for more info
                  </Link>
                </footer>
              </div>
            </div>

            <div className="column">
              <div className="card">
                {/* <p className="card-header-title is-centered is-size-3 firm-color">
                  delivery
                </p> */}
                <h1 className="card-header-title is-size-3 is-centered has-text-weight-normal">
                  Delivery
                </h1>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={furniture} alt="delivery"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    Have you bought furniture but the warehouse wants you to
                    make your own delivery arrangement or are you looking for
                    quick deliveries? We can assist.
                  </p>
                </div>
                <footer className="card-footer blue-background-color">
                  <Link
                    to="/services"
                    className="card-footer-item has-text-white"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Click here for more info
                  </Link>
                </footer>
              </div>
            </div>

            <div className="column">
              <div className="card">
                {/* <p className="card-header-title is-centered is-size-3 firm-color">
                  partnership
                </p> */}
                <h1 className="card-header-title is-size-3 is-centered has-text-weight-normal">
                  Business Partnership
                </h1>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={partnership} alt="partnership"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    Do you run a business and looking for a reputable company to
                    make deliveries for you, on time and in a professional
                    manner? let us help.
                  </p>
                </div>
                <footer className="card-footer blue-background-color">
                  <Link
                    to="/services"
                    className="card-footer-item has-text-white"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Click here for more info
                  </Link>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <QuoteComp></QuoteComp>
      </div>

      <div>
        <ContactForm></ContactForm>
      </div>

      <section className="section">
        <div className="container">
          <div className="title">
            <h1 className="title has-text-centered has-text-weight-bold">
              Why choose instalorry?
            </h1>
          </div>
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <div className="content">
                    <ul className="has-bullets">
                      <li>
                        <strong>Quality:</strong> We provide a service that
                        exceeds customer expectations.
                      </li>
                      <li>
                        <strong>Professionalism:</strong> We treat our customers
                        with respect.
                      </li>
                      <li>
                        <strong>Reliability:</strong> We strive to honour our
                        agreements and meet deadlines.
                      </li>
                      <li>
                        <strong>Transparency:</strong> We are open throughout
                        our business processes.
                      </li>
                      <li>
                        <strong>Innovation:</strong> We continuously strive to
                        improve our services to stay relevant.
                      </li>
                      <li>
                        <strong>Adaptability:</strong> We can adapt effectively
                        and efficiently to meet client demands.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add the Reviews component */}
      <section className="section pt-1">
        <div className="container">
          <div className="title">
            <h1 className="title has-text-centered has-text-weight-bold">
              Our happy clients
            </h1>
          </div>

          <div className="columns is-centered">
            <ReviewSection></ReviewSection>
          </div>
        </div>
      </section>

      {/* <LiveChat></LiveChat> */}
    </div>
  );
};

export default HomeComp;
