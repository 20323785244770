import React from "react";
import { NavLink } from "react-router-dom";

const PrivacyPolicyComp = () => {
  const goBack = () => {
    window.history.back(); // This will take the user to the previous page.
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <h1 className="title">Privacy Policy for Instalorry (Pty) Ltd</h1>
          <br></br>

          <h2 className="subtitle">
            <strong>Last Updated: [06 September 2023]</strong>
          </h2>

          <h3 className="subtitle">
            <strong>1. Introduction:</strong>
          </h3>
          <p>
            Instalorry (Pty) Ltd, trading as Instalorry, is committed to
            protecting your privacy and ensuring the security of your personal
            information. This Privacy Policy explains how we collect, use, and
            safeguard your data when you use our app and services. By using
            Instalorry, you consent to the practices described in this policy.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>2. Types of Data We Collect</strong>
          </h3>
          <p>
            <ul>
              <li>
                <strong>User Data:</strong> When you create an account, we
                collect your name, contact details, and other information
                necessary to provide our services.
              </li>
              <li>
                <strong>Payment Information: </strong>When processing payments,
                we collect payment details, such as credit card information, to
                facilitate transactions.
              </li>
              <li>
                <strong>Usage Data:</strong> We collect data about how you
                interact with our app, including your preferences, settings, and
                device information.
              </li>
            </ul>
            Instalorry (Pty) Ltd, trading as Instalorry, facilitates the
            transportation of goods between driver partners and customers
            through the Instalorry app. The drivers transport goods on behalf of
            customers.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>3. How We Use Your Data</strong>
          </h3>
          <p>We use your data for the following purposes:</p>
          <br></br>
          <p>
            <ul>
              <li>
                <strong>Service Delivery:</strong> We use your information to
                provide transportation and delivery services, connect drivers
                with customers, and process payments.
              </li>
              <li>
                <strong>Enhancing User Experience:</strong> We personalize your
                experience, offer customer support, and improve our services
                based on your feedback and usage patterns.
              </li>
              <li>
                <strong>Safety and Security:</strong> We use data to ensure the
                safety and security of our users and platform, including
                verifying driver qualifications and addressing safety concerns.
              </li>
            </ul>
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>4. Sharing Your Data</strong>
          </h3>
          <p>We may share your data with:</p>
          <br></br>
          <p>
            <ul>
              <li>
                <strong>Driver Partners:</strong> We share your name and
                location details with driver partners to facilitate
                transportation services.
              </li>
              <li>
                <strong>Payment Processors:</strong> We share payment
                information with trusted payment processors to facilitate
                transactions securely.
              </li>
              <li>
                <strong>Regulatory Authorities:</strong> We may share data when
                required by applicable laws and regulations.
              </li>
              <li>
                <strong>Legal Authorities:</strong> We may disclose information
                in response to legal requests or to protect our rights and
                interests.
              </li>
            </ul>
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>5. Data Security</strong>
          </h3>
          <p>
            We implement security measures to protect your data from
            unauthorized access, disclosure, alteration, or destruction. We use
            encryption, access controls, and regular security audits to
            safeguard your information.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>6. User Rights</strong>
          </h3>
          <p>You have the right to:</p>
          <br></br>
          <p>
            <ul>
              <li>Have your data accessed, corrected or deleted</li>
              <li>Restrict or object to data processing.</li>
              <li>Withdraw consent (where applicable).</li>
            </ul>
          </p>
          <br></br>
          <p>To exercise these rights, please contact us.</p>
          <br></br>

          <h3 className="subtitle">
            <strong>7. Data Retention</strong>
          </h3>
          <p>
            We retain your data only for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy or as required by
            applicable laws.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>8. Cookies and Tracking</strong>
          </h3>
          <p>
            We use cookies and similar technologies to enhance user experience
            and gather usage data. You can manage your cookie preferences
            through your device settings.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>9. Third-Party Services</strong>
          </h3>
          <p>
            We may use third-party services for analytics, advertising, or other
            purposes. These services have their own privacy policies, and we
            encourage you to review them.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>10. Updates to Privacy Policy</strong>
          </h3>
          <p>
            We may update this Privacy Policy to reflect changes in our
            practices. We will notify you of any significant updates through the
            app or by other means.
          </p>
          <br></br>

          <h3 className="subtitle">
            <strong>11. Legal Compliance</strong>
          </h3>
          <p>
            We are committed to complying with all applicable data protection
            laws, including the Protection of Personal Information Act (POPIA)
            in South Africa.
          </p>
          <br></br>

          <div>
            <NavLink
              to={"/terms"}
              className=""
              onClick={() => {
                // to ensure you end up at the top of the page when going to link
                window.scroll(0, 0);
              }}
            >
              <strong>Terms and Conditions</strong>
            </NavLink>
          </div>
          <br></br>

          <div>
            <button onClick={goBack} className="button is-success">
              Back
            </button>{" "}
            {/**to help navigate to the previous page after reading the terms and conditions */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicyComp;
