import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";
import ConfirmationModal from "../general/ConfirmationModal";

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getClients = useCallback(async () => {
    try {
      const response = await axios.get("/api/clients/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClients(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getClients(); // Call getUsers immediately after defining it
  }, [getClients]);
  // not necessary to add getUsers as a dependency
  // this is because getUsers does not depend on this dependency to execute
  // this is defined in the useCallback function

  const deleteClient = async (clientId) => {
    try {
      const response = await axios.delete(`api/clients/delete/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.msg);
    } catch (error) {
      alert(error.response.data.msg);
    }
    getClients();
  };

  const openModal = (clientId) => {
    setClientToDelete(clientId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setClientToDelete(null);
  };

  const confirmDelete = () => {
    if (clientToDelete !== null) {
      deleteClient(clientToDelete);
    }
    closeModal();
  };

  return (
    <div>
      <h1 className="title">Customers</h1>
      <h2 className="subtitle">List of customers</h2>

      <div className="table-container">
        {" "}
        {/* Apply responsive container */}
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Id</th>
              <th>Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Cellphone</th>
              <th>Status</th>
              <th>Client Type</th>
              <th>Corporate Interest</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={client.id}>
                <td>{index + 1}</td>
                <td>{client.id}</td>
                <td>{client.name}</td>
                <td>{client.surname}</td>
                <td>{client.email}</td>
                <td>{client.cellphone}</td>
                <td>{client.status}</td>
                <td>{client.clientType}</td>
                <td>
                  {client.corporateInterest !== null
                    ? String(client.corporateInterest)
                    : "N/A"}
                </td>
                <td>
                  <Link
                    to={`/clients/update/${client.id}`}
                    className="button is-small is-info"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Edit
                  </Link>
                  {/* <button
                    onClick={() => deleteClient(client.id)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button> */}
                  <button
                    onClick={() => openModal(client.id)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen}
        onConfirm={confirmDelete}
        onCancel={closeModal}
        message="Are you sure you want to delete this client?"
      />
    </div>
  );
};

export default ClientList;
