import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNotes, setAgreedToTerms } from "../../features/orderSlice";
import { setOrderNavLink } from "../../features/generalSlice";

const OrderTermsComp = () => {
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { notes, agreedToTerms } = useSelector((store) => store.order);
  const { party } = useSelector((store) => store.auth);

  const handleNext = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }
    navigate("/orders/checkout");
    window.scroll(0, 0);
  };

  const handleBack = () => {
    navigate("/orders/pricebreakdown");
    window.scroll(0, 0);
  };

  const handleNavDestination = () => {
    dispatch(setOrderNavLink("/orders/terms"));
  };

  const validateFields = () => {
    const errors = {};
    if (!agreedToTerms) {
      errors.agreedToTerms = "Please agree to the terms and conditions";
    }
    if (!party || party.type !== "client") {
      errors.party = "Please login to your account";
    }

    if (!party || party.type !== "client" || !agreedToTerms) {
      setOverallError("*Please complete all the required information");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6">
              <div className="title">
                <h1 className="title has-text-centered section-header">
                  Agreement to Terms and Conditions
                </h1>
              </div>

              <form>
                {(!party || party.type !== "client") && (
                  <div>
                    <div className="field mb-1">
                      <div className="control">
                        <p className={`is-warning`}>
                          Please ensure you have a customer account and have
                          logged in before you can checkout
                        </p>
                      </div>
                    </div>

                    <div className=" field is-grouped is-centered">
                      <div className={`control`}>
                        <NavLink
                          to={"/clients/login"}
                          className="column"
                          onClick={() => {
                            handleNavDestination();
                          }}
                        >
                          Link to login or register
                        </NavLink>
                      </div>
                      {/* <div className="control">
                        <NavLink
                          to={"/clients/register"}
                          className="column"
                          onClick={() => {
                            // to ensure you end up at the top of the page when going to link
                            window.scroll(0, 0);
                          }}
                        >
                          Register
                        </NavLink>
                      </div> */}
                    </div>
                    {errors.party && (
                      <p className="help is-danger">{errors.party}</p>
                    )}
                  </div>
                )}
                <br></br>

                <div className={`field`}>
                  <label className="label blue-font-color">
                    Additional notes
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      placeholder="e.g. The items being moved, important info to note, etc."
                      value={notes}
                      onChange={(e) => dispatch(setNotes(e.target.value))}
                    ></textarea>
                  </div>
                </div>
                <br></br>

                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={agreedToTerms}
                        onChange={() =>
                          dispatch(setAgreedToTerms(!agreedToTerms))
                        }
                      />{" "}
                      I agree to the{" "}
                      <NavLink
                        to="/terms"
                        onClick={() => {
                          // to ensure you end up at the top of the page when going to link
                          window.scroll(0, 0);
                        }}
                      >
                        Terms and Conditions
                      </NavLink>
                    </label>
                  </div>
                  {errors.agreedToTerms && (
                    <p className="help is-danger">{errors.agreedToTerms}</p>
                  )}
                </div>
                <br></br>

                {overallError && (
                  <div>
                    <p className="has-text-centered has-text-danger">
                      {overallError}
                    </p>
                    <br></br>
                  </div>
                )}

                {/* <div className="field">
                  <div className="control">
                    
                  </div>
                </div>
                <br></br> */}

                {/* <div className="field">
                  <div className="control">
                    <p className={`is-warning`}>
                      Click below to change info on previous page
                    </p>
                  </div>
                </div> */}

                <div className="field">
                  <div className="control">
                    <div className="buttons is-centered">
                      <button
                        type="button"
                        onClick={handleBack}
                        className={`button blue-background-color has-text-white is-rounded`}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={handleNext}
                        className={`button blue-background-color has-text-white is-rounded`}
                      >
                        Go to checkout
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderTermsComp;
