import React from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import InfoIconModal from "../general/InfoIconModal";
import {
  setName,
  setSurname,
  setPassword,
  setConfPassword,
  setCellphone,
  setArea,
  setAgreedToTerms,
  addVehicle,
  removeVehicle, // Import removeVehicle action
  updateVehicle, // Import updateVehicle action
} from "../../features/driverRegSlice";

const AddDriverForm = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  const {
    name,
    surname,
    password,
    confPassword,
    cellphone,
    area,
    agreedToTerms,
    email,
    vehicles,
  } = useSelector((state) => state.regDriver);
  const navigate = useNavigate();

  // Password validation function
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 10) {
      errors.push("Password must be at least 10 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    return errors;
  };

  // frontend fields validation for completeness
  const validateFields = () => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!surname) {
      errors.surname = "Surname is required";
    }
    if (!password) {
      errors.password = "Password is required";
    } else {
      const passwordErrors = validatePassword(password);
      if (passwordErrors.length > 0) {
        errors.password = passwordErrors.join(" ");
      }
    }
    if (!confPassword) {
      errors.confPassword = "Confirm Password is required";
    } else if (confPassword !== password) {
      errors.confPassword = "Passwords do not match";
    }
    if (!cellphone) {
      errors.cellphone = "Cellphone is required";
    }
    if (!area) {
      errors.area = "Area is required";
    }

    vehicles.forEach((vehicle, index) => {
      if (!vehicle.truckSize) {
        errors[`truckSize${index}`] = "Truck Size is required";
      }
      if (!vehicle.licencePlate) {
        errors[`licencePlate${index}`] = "Vehicle licence plate is required";
      }
      if (!vehicle.shape) {
        errors[`shape${index}`] = "Vehicle body shape is required";
      }
      if (!vehicle.git) {
        errors[`git${index}`] = "The GIT field is required";
      }
    });

    if (!agreedToTerms) {
      errors.agreedToTerms = "Please agree to the Terms and Conditions";
    }

    if (
      !name ||
      !surname ||
      !password ||
      !confPassword ||
      !cellphone ||
      !area ||
      !agreedToTerms
    ) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  const saveDriver = async (e) => {
    e.preventDefault();
    // letting this stay here causes errors
    setIsLoading(true);

    // Check if the modal is active before proceeding
    // if (isModalActive) {
    //   setIsLoading(false);
    //   toggleModal(); // Close the modal
    //   return;
    // }

    // validate the completeness of the fields
    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post("/api/drivers/register", {
        driverDetails: {
          name: name,
          surname: surname,
          email: email,
          password: password,
          confPassword: confPassword,
          cellphone: cellphone,
          area: area,
          ownerDriver: "yes",
        },
        vehicles,
        ownerDetails: {
          name: name,
          surname: surname,
          email: email,
          cellphone: cellphone,
          area: area,
        },
      });
      setMsg(response.data.msg);
      alert(
        "Your information has been received, your account is pending. Please await further communication."
      );
      navigate("/");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddVehicle = (e) => {
    e.preventDefault(); // if you don't do this form will attempt to submit causing errors
    dispatch(addVehicle()); // Dispatch action to add a new vehicle
  };

  // Function to handle updating vehicle information
  const handleUpdateVehicle = (index, fieldName, value) => {
    dispatch(updateVehicle({ index, data: { [fieldName]: value } }));
  };

  const handleRemoveVehicle = (e, indexToRemove) => {
    e.preventDefault();
    dispatch(removeVehicle(indexToRemove)); // Dispatch action to remove the vehicle
  };

  // const clearForm = () => {
  //   dispatch(setName(""));
  //   dispatch(setSurname(""));
  //   dispatch(setEmail(""));
  //   dispatch(setPassword(""));
  //   dispatch(setConfPassword(""));
  //   dispatch(setCellphone(""));
  //   dispatch(setArea(""));
  //   dispatch(setAgreedToTerms(""));
  //   dispatch(setVerificationCode(null));
  //   dispatch(setVerifCodeSent(false));
  //   dispatch(setVerifiedEmail(false));
  //   dispatch(resetVehicles());
  // };

  return (
    <div>
      <div>
        <h1 className="title has-text-centered has-text-weight-bold">
          Drivers
        </h1>
        <h1 className="subtitle has-text-centered blue-font-color">
          Become a driver
        </h1>

        {/* <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column">
                <div className="card box">
                  <p className="card-header-title is-centered is-size-6">
                    Do you own a truck or bakkie? Well we have the clients!
                    Together we can grow and make money.
                  </p>
                  <p className="card-header-title is-centered is-size-6">
                    Please note that your account will be placed on 'pending'
                    once you register. Only after you send the required
                    documents will your account status be changed to 'active'.
                  </p>
                  <div className="card-header-title is-centered is-size-6">
                    <ol>
                      {" "}
                      The required documents are:
                      <li>Driver's licence</li>
                      <li>Vehicle licence disc</li>
                      <li>Driver passport or id sized photo</li>
                      <li>Proof of GIT (if any - serves as an advantage)</li>
                      <li>Front photo of the vehicle (show licence plate)</li>
                      <li>Back photo of the vehicle (show licence plate)</li>
                      <li>Right side photo of the vehicle</li>
                      <li>Left side photo of the vehicle</li>
                      Send the documents to the Whatsapp number: 065 870 2019
                    </ol>
                  </div>
                  <p className="card-header-title is-centered is-size-5
                         has-text-weight-light firm-color">
                            A commission of only 12% will be retained or charged on all the jobs. This is 
                            significantly low compared to the market. At instaLorry we value our drivers
                            and realise that they do most of the work, it is for this reason that we charged
                            a low commission.
                        </p> 
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section>
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="card">
                <p className="card-header-title is-centered blue-font-color is-size-4">
                  Driver Information
                </p>
                <div className="card-content">
                  <div className="content">
                    <form onSubmit={saveDriver}>
                      <div className="field">
                        <label className="label blue-font-color">Name</label>
                        <div className="control">
                          <input
                            className={`input ${
                              errors.name ? "is-danger" : ""
                            }`}
                            value={name}
                            onChange={(e) => dispatch(setName(e.target.value))}
                          ></input>
                        </div>
                        {errors.name && (
                          <p className="help is-danger">{errors.name}</p>
                        )}
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">Surname</label>
                        <div className="control">
                          <input
                            className={`input ${
                              errors.surname ? "is-danger" : ""
                            }`}
                            value={surname}
                            onChange={(e) =>
                              dispatch(setSurname(e.target.value))
                            }
                          ></input>
                        </div>
                        {errors.surname && (
                          <p className="help is-danger">{errors.surname}</p>
                        )}
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">
                          Password
                        </label>
                        <div className="control">
                          <input
                            className={`input ${
                              errors.password ? "is-danger" : ""
                            }`}
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) =>
                              dispatch(setPassword(e.target.value))
                            }
                          ></input>
                          {/* <InfoIconModal></InfoIconModal> */}
                          <button
                            type="button"
                            className="button is-green is-small is-primary mt-1"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? "👁️" : "👁️‍🗨️"}
                          </button>
                        </div>
                        {errors.password && (
                          <p className="help is-danger">{errors.password}</p>
                        )}
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">
                          Confirm Password
                        </label>
                        <div className="control">
                          <input
                            className={`input ${
                              errors.confPassword ? "is-danger" : ""
                            }`}
                            type={showPassword ? "text" : "password"}
                            value={confPassword}
                            onChange={(e) =>
                              dispatch(setConfPassword(e.target.value))
                            }
                          ></input>
                          <button
                            type="button"
                            className="button is-green is-small is-primary mt-1"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? "👁️" : "👁️‍🗨️"}
                          </button>
                        </div>
                        {errors.confPassword && (
                          <p className="help is-danger">
                            {errors.confPassword}
                          </p>
                        )}
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">
                          Cellphone
                        </label>
                        <div className="control">
                          <input
                            className={`input ${
                              errors.cellphone ? "is-danger" : ""
                            }`}
                            value={cellphone}
                            onChange={(e) =>
                              dispatch(setCellphone(e.target.value))
                            }
                          ></input>
                        </div>
                        {errors.cellphone && (
                          <p className="help is-danger">{errors.cellphone}</p>
                        )}
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">
                          Area based
                        </label>
                        <div className="control">
                          <input
                            className={`input ${
                              errors.area ? "is-danger" : ""
                            }`}
                            value={area}
                            onChange={(e) => dispatch(setArea(e.target.value))}
                          ></input>
                        </div>
                        {errors.area && (
                          <p className="help is-danger">{errors.area}</p>
                        )}
                      </div>

                      <br></br>
                      <p className="card-header-title is-centered blue-font-color is-size-4">
                        Vehicle(s) Information
                      </p>

                      {vehicles.map((vehicle, index) => (
                        <div key={index} className="vehicle-field mb-6">
                          <div className="field">
                            <label className="label blue-font-color">
                              Truck Size
                            </label>
                            <div className="control">
                              <div
                                className={`select ${
                                  errors[`truckSize${index}`] ? "is-danger" : ""
                                }`}
                              >
                                <select
                                  value={vehicle.truckSize}
                                  onChange={(e) =>
                                    handleUpdateVehicle(
                                      index,
                                      "truckSize",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Please select</option>
                                  <option value="0.5ton">half ton</option>
                                  <option value="1ton">1 ton</option>
                                  <option value="1.5ton">1.5 ton</option>
                                  <option value="3ton">3 ton</option>
                                  <option value="8ton">8 ton</option>
                                </select>
                              </div>
                            </div>
                            {errors[`truckSize${index}`] && (
                              <p className="help is-danger">
                                {errors[`truckSize${index}`]}
                              </p>
                            )}
                          </div>

                          <div className="field">
                            <label className="label blue-font-color">
                              Licence Plate
                            </label>
                            <div className="control">
                              <input
                                className={`input ${
                                  errors[`licencePlate${index}`]
                                    ? "is-danger"
                                    : ""
                                }`}
                                value={vehicle.licencePlate}
                                onChange={(e) =>
                                  handleUpdateVehicle(
                                    index,
                                    "licencePlate",
                                    e.target.value
                                  )
                                }
                              ></input>
                            </div>
                            {errors[`licencePlate${index}`] && (
                              <p className="help is-danger">
                                {errors[`licencePlate${index}`]}
                              </p>
                            )}
                          </div>

                          <div className="field">
                            <label className="label blue-font-color">
                              Body shape
                            </label>
                            <div className="control">
                              <div
                                className={`select ${
                                  errors[`shape${index}`] ? "is-danger" : ""
                                }`}
                              >
                                <select
                                  value={vehicle.shape}
                                  onChange={(e) =>
                                    handleUpdateVehicle(
                                      index,
                                      "shape",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Please select</option>
                                  <option value="open">Open</option>
                                  <option value="closed">Closed</option>
                                </select>
                              </div>
                            </div>
                            {errors[`shape${index}`] && (
                              <p className="help is-danger">
                                {errors[`shape${index}`]}
                              </p>
                            )}
                          </div>

                          <div className="field">
                            <label className="label blue-font-color">
                              Do you have GIT - Goods In Transit Insurance
                            </label>
                            <div className="control">
                              <div
                                className={`select ${
                                  errors[`git${index}`] ? "is-danger" : ""
                                }`}
                              >
                                <select
                                  value={vehicle.git}
                                  onChange={(e) =>
                                    handleUpdateVehicle(
                                      index,
                                      "git",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Please select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            {errors[`git${index}`] && (
                              <p className="help is-danger">
                                {errors[`git${index}`]}
                              </p>
                            )}
                          </div>

                          {/* Button to remove the vehicle */}
                          <button
                            className="button is-danger is-small"
                            onClick={(e) => handleRemoveVehicle(e, index)}
                          >
                            Remove Vehicle
                          </button>
                        </div>
                      ))}

                      {/* Button to Add New Vehicle */}
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            className="button is-primary"
                            onClick={handleAddVehicle}
                          >
                            Add Another Vehicle
                          </button>
                        </div>
                      </div>

                      <br></br>
                      <p className="card-header-title is-centered blue-font-color is-size-4">
                        Agreement to Terms and Conditions
                      </p>

                      <div className="field">
                        <div className="control">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={agreedToTerms}
                              onChange={() =>
                                dispatch(setAgreedToTerms(!agreedToTerms))
                              }
                            />{" "}
                            I agree to the{" "}
                            <NavLink
                              to="/terms"
                              onClick={() => {
                                // to ensure you end up at the top of the page when going to link
                                window.scroll(0, 0);
                              }}
                            >
                              Terms and Conditions
                            </NavLink>
                          </label>
                        </div>
                        {errors.agreedToTerms && (
                          <p className="help is-danger">
                            {errors.agreedToTerms}
                          </p>
                        )}
                      </div>

                      {/**error from the backend */}
                      <p className="has-text-centered has-text-weight-bold">
                        {msg}
                      </p>

                      {overallError && (
                        <p className="has-text-centered has-text-danger">
                          {overallError}
                        </p>
                      )}

                      <div className="field">
                        <div className="control">
                          <button
                            disabled={isLoading}
                            onClick={saveDriver}
                            className="button blue-background-color has-text-white is-rounded is-fullwidth"
                          >
                            {isLoading ? "Loading..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/**Modal */}
      {/* <div
        className={`modal ${isModalOpen === "is-active" ? "is-active" : ""}`}
      >
        <div className="modal-background"></div>
        <div className="modal-content">
          <article className="message is-warning is-size-5 is-half-mobile">
            <div className="message-header">
              <p>{`You will be sent a verification code. Is the following email correct: ${email}`}</p>
            </div>
            <div className="message-body is-flex is-justify-content-between">
              <div className="is-flex-grow-1">
                <button
                  disabled={isLoading}
                  onClick={() => saveDriver()}
                  className="button is-small is-success is-size-6"
                >
                  {isLoading ? "Loading..." : "Yes"}
                </button>
              </div>
              <div>
                <button
                  onClick={closeModal}
                  className="button is-small is-success is-size-6 is-end"
                >
                  Change email
                </button>
              </div>
            </div>
          </article>
        </div>
        <button
          onClick={closeModal}
          className="modal-close is-large"
          aria-label="close"
        ></button>
      </div> */}
    </div>
  );
};

export default AddDriverForm;

// notes
// (1):

// the ... spread syntax is used to expand elements of an iterable (array or object) into individual elements
// const arr1 = [1, 2, 3]
// console.log(...arr1)
// outputs: 1 2 3
// which is essentially the same as: console.log(1, 2, 3)

// to push the items back into an array:
// let arr1 = [];
//  arr1.push(1, 2);
//  console.log(arr1);

// const arr1 = [1, 2, 3];
//   const arr2 = [4, 5, 6];
//   // console.log([...arr1, ...arr2]);
//   will output [1, 2, 3, 4, 5, 6]
