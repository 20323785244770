import React from "react";
import Layout from "./Layout";
import AboutComp from "../../components/general/AboutComp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMe } from "../../features/authSlice";

const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <Layout>
      <AboutComp></AboutComp>
    </Layout>
  );
};

export default About;
