import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  surname: "",
  email: "",
  password: "",
  confPassword: "",
  cellphone: "",
  agreedToTerms: false,
  corporateInterest: "no",
  verificationCode: null,
  verifCodeSent: false,
  verifiedEmail: false,
};

const clientRegSlice = createSlice({
  name: "regClient",
  initialState,
  reducers: {
    resetClientReg: () => initialState,
    setName: (state, action) => {
      state.name = action.payload;
    },
    setSurname: (state, action) => {
      state.surname = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setConfPassword: (state, action) => {
      state.confPassword = action.payload;
    },
    setCellphone: (state, action) => {
      state.cellphone = action.payload;
    },
    setAgreedToTerms: (state, action) => {
      state.agreedToTerms = action.payload;
    },
    setCorporateInterest: (state, action) => {
      state.corporateInterest = action.payload;
    },
    setVerificationCode: (state, action) => {
      state.verificationCode = action.payload;
    },
    setVerifCodeSent: (state, action) => {
      state.verifCodeSent = action.payload;
    },
    setVerifiedEmail: (state, action) => {
      state.verifiedEmail = action.payload;
    },
  },
});

export const {
  setName,
  setSurname,
  setPassword,
  setConfPassword,
  setEmail,
  setCellphone,
  setAgreedToTerms,
  setCorporateInterest,
  setVerificationCode,
  setVerifCodeSent,
  setVerifiedEmail,
  resetClientReg,
} = clientRegSlice.actions;

export default clientRegSlice.reducer;
