import React from "react";

const ConfirmationModal = ({ isModalOpen, onConfirm, onCancel, message }) => {
  return (
    <div className={`modal ${isModalOpen ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <article className="message is-warning">
          <div className="message-header">
            <p>{message}</p>
          </div>
          <div className="message-body is-flex is-justify-content-space-between">
            <button onClick={onConfirm} className="button is-small is-success">
              Yes
            </button>
            <button onClick={onCancel} className="button is-small is-danger">
              No
            </button>
          </div>
        </article>
      </div>
      <button
        onClick={onCancel}
        className="modal-close is-large"
        aria-label="close"
      ></button>
    </div>
  );
};

export default ConfirmationModal;
