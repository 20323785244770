import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";

const PricingList = () => {
  const [prices, setPrices] = useState([]);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getPricing = useCallback(async () => {
    try {
      const response = await axios.get("/api/pricing", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPrices(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getPricing();
  }, [getPricing]);

  const deletePrice = async (truckSize) => {
    // can call parameter anything
    await axios.delete(`/api/pricing/${truckSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    getPricing(); // this is so that we can see the changes on the prices interface
  };

  return (
    <div>
      <h1 className="title">Prices</h1>
      <h2 className="subtitle">Price list for the trucks</h2>

      <Link
        to="/pricing/create"
        className="button is-primary mb-2"
        onClick={() => {
          // to ensure you end up at the top of the page when going to link
          window.scroll(0, 0);
        }}
      >
        Add New Truck
      </Link>

      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Id</th>
              <th>Truck Size</th>
              <th>Base Amount</th>
              <th>Rate Per Km</th>
              <th>Price per helper</th>
              <th>Price per floor</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((price, index) => (
              <tr key={price.id}>
                <td>{index + 1}</td>
                <td>{price.id}</td>
                <td>{price.truckSize}</td>
                <td>{price.baseAmount}</td>
                <td>{price.ratePerKm}</td>
                <td>{price.helpersPricing}</td>
                <td>{price.floorsPricing}</td>
                <td>
                  <Link
                    to={`/pricing/${price.truckSize}`}
                    className="button is-small is-info"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => deletePrice(price.truckSize)}
                    className="button is-small is-danger"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingList;
