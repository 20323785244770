import React from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";
import ConfirmationModal from "../general/ConfirmationModal";

const OwnerList = () => {
  const [owners, setOwners] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownerToDelete, setOwnerToDelete] = useState(null);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getOwners = useCallback(async () => {
    try {
      const response = await axios.get("/api/owners/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOwners(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getOwners(); // Call getUsers immediately after defining it
  }, [getOwners]);
  // not necessary to add getUsers as a dependency
  // this is because getUsers does not depend on this dependency to execute
  // this is defined in the useCallback function

  const deleteOwner = async (ownerId) => {
    try {
      const response = await axios.delete(`api/owners/delete/${ownerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.msg);
    } catch (error) {
      alert(error.response.data.msg);
    }
    getOwners();
  };

  const openModal = (ownerId) => {
    setOwnerToDelete(ownerId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOwnerToDelete(null);
  };

  const confirmDelete = () => {
    if (ownerToDelete !== null) {
      deleteOwner(ownerToDelete);
    }
    closeModal();
  };

  return (
    <div>
      <h1 className="title">Owners</h1>
      <h2 className="subtitle">List of owners</h2>

      <div className="table-container">
        {" "}
        {/* Apply responsive container */}
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Id</th>
              <th>Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Cellphone</th>
              <th>Area</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {owners.map((owner, index) => (
              <tr key={owner.id}>
                <td>{index + 1}</td>
                <td>{owner.id}</td>
                <td>{owner.name}</td>
                <td>{owner.surname}</td>
                <td>{owner.email}</td>
                <td>{owner.cellphone}</td>
                <td>{owner.area}</td>
                <td>
                  {/* <Link
                    to={`/clients/update/${owner.id}`}
                    className="button is-small is-info"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Edit
                  </Link> */}
                  {/* <button
                    onClick={() => deleteClient(client.id)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button> */}
                  <button
                    onClick={() => openModal(owner.id)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen}
        onConfirm={confirmDelete}
        onCancel={closeModal}
        message="Are you sure you want to delete this owner?"
      />
    </div>
  );
};

export default OwnerList;
