import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/authSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { party } = useSelector((store) => store.auth);

  const handleLogout = (e) => {
    e.preventDefault(); // because this is called within navlink which is not meant for onClick events
    dispatch(logout());
    dispatch(reset());
    navigate("/");
    alert("Logout successful");
  };

  return party ? (
    <div>
      <aside className={`menu has-shadow pl-3 pt-2 has-text-weight-bold`}>
        <p className="menu-label has-text-weight-bold is-size-6">GENERAL</p>
        <ul className="menu-list has-text-weight-bold">
          <li>
            <NavLink to="/dashboard">Dashboard</NavLink>
          </li>
        </ul>
        <p className="menu-label has-text-weight-bold is-size-6">WORKBENCH</p>
        <ul className="menu-list has-text-weight-bold">
          {/* <li> */}
          {/* <div className="menu-folder has-text-weight-bold">Orders</div> */}
          {/* <ul className="menu-list"> */}
          <li>
            <NavLink to="/orders/all">Orders</NavLink>
          </li>
          {/* </ul> */}
          {party && party.type === "user" && (
            <div>
              {/* <div className="menu-folder">Clients</div> */}
              {/* <ul className="menu-list"> */}
              <li>
                <NavLink to="/clients/all">Clients</NavLink>
              </li>
              {/* </ul> */}
              {/* <div className="menu-folder">Drivers</div> */}
              {/* <ul className="menu-list"> */}
              <li>
                <NavLink to="/owners/all">Owners</NavLink>
              </li>
              <li>
                <NavLink to="/drivers/all">Drivers</NavLink>
              </li>
              {/* </ul> */}
              {/* <div className="menu-folder">Vehicles</div> */}
              {/* <ul className="menu-list"> */}
              <li>
                <NavLink to="/vehicles/all">Vehicles</NavLink>
              </li>
              {/* </ul> */}
              <NavLink to="/otps/all">OTPs</NavLink>
            </div>
          )}
          {/* </li> */}
        </ul>
        <br></br>

        {party && party.type === "user" && (
          <div>
            <p className="menu-label has-text-weight-bold is-size-6">
              ADMINISTRATION
            </p>
            <ul className="menu-list">
              {/* <li> */}
              {party &&
                party.type === "user" &&
                (party.role === "admin" ? (
                  <div>
                    {/* <div className="menu-folder">Users</div> */}
                    {/* <ul className="menu-list"> */}
                    <li>
                      <NavLink to="/users/all">All users</NavLink>
                      <NavLink to="">Suspended users</NavLink>
                    </li>
                    {/* </ul> */}
                  </div>
                ) : null)}

              <NavLink to="/pricing">Pricing List</NavLink>
              {/* </li> */}
            </ul>
          </div>
        )}

        <p className="menu-label has-text-weight-bold is-size-6">SETTINGS</p>
        <ul className="menu-list">
          <li>
            <NavLink to="/" onClick={handleLogout}>
              Logout
            </NavLink>
          </li>
          {/* <button onClick={handleLogout}>Logout</button> */}
        </ul>
      </aside>
    </div>
  ) : null;
};

export default Sidebar;

// {party &&
//     party.type === "user" &&
//     (party.role === "admin" ? () : null)}
