import React from "react";
import { NavLink } from "react-router-dom";

const TsCsComp = () => {
  const goBack = () => {
    window.history.back(); // This will take the user to the previous page.
  };

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Terms and Conditions for Instalorry (Pty) Ltd</h1>
        <br></br>

        <h2 className="subtitle">
          <strong>Trading Name: Instalorry</strong>
        </h2>

        <h2 className="subtitle">
          <strong>Legal Name: Instalorry (Pty) Ltd</strong>
        </h2>

        <p>
          These terms and conditions ("Terms") govern the use of the Instalorry
          app and services. By using the Instalorry app, both the driver and the
          customer agree to abide by these terms and conditions.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>1. Acceptance of Terms</strong>
        </h3>
        <p>
          By accessing or using the Instalorry app, you acknowledge that you
          have read, understood, and agree to be bound by these Terms.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>2. Service Description</strong>
        </h3>
        <p>
          Instalorry (Pty) Ltd, trading as Instalorry, facilitates the
          transportation of goods between a driver and a customer through the
          Instalorry app. The driver transports goods on behalf of a customer.
          The service is limited to the transportation of goods and shall not
          include the transportation of the customer himself/herself. A customer
          might ask to travel with the driver during the service and the driver
          might accept to do so but at their own risk. Instalorry is not in the
          business of transporting customers for profit making.
        </p>
        <br></br>

        <p>
          It is important to note that instalorry does not provide the actual
          transportation service rather it provides the platform where the
          customer can book or request the transportation service. The platform
          is provided through the instalorry website or app. Thereafter, a
          registered driver will accept and perform the requested transportation
          service.
        </p>

        <br></br>

        <h3 className="subtitle">
          <strong>3. User Eligibility</strong>
        </h3>
        <p>
          Users must be 18 years of age or older, as required by South African
          law under the Protection of Personal Information Act (POPIA), to use
          the app.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>4. Definitions:</strong>
        </h3>
        <p>
          <strong>Customer:</strong> A customer refers to an individual or
          entity who uses the Instalorry app to request transportation services
          for the movement of goods.
        </p>
        <br></br>

        <p>
          <strong>User:</strong> A User encompasses both the customer and the
          driver, referring collectively to an individual or entity who accesses
          and utilizes the Instalorry app and its services.
        </p>
        <br></br>

        <p>
          <strong>Driver:</strong> A Driver is an individual or entity who uses
          the Instalorry app to provide transportation services for the movement
          of goods on behalf of the customer.
        </p>
        <br></br>

        <p>
          <strong>Once-Off Client:</strong> A once-off client is a customer who
          uses the Instalorry app for a single or occasional transportation
          service without any ongoing contractual arrangement.
        </p>
        <br></br>

        <p>
          <strong>Contract Client:</strong> A contract client is a customer who
          has an ongoing contractual arrangement with Instalorry for multiple or
          continuous transportation services over a specified period.
        </p>
        <br></br>

        <p>
          <strong>Order:</strong> An order is a request for transportation
          services made by a customer through the Instalorry app, which includes
          details such as the pickup and delivery locations, type and quantity
          of goods, and the order value. The order is confirmed once the driver
          accepts the request and the necessary commission is deducted from the
          driver's wallet (if applicable).
        </p>
        <br></br>

        <p>
          <strong>Order Value:</strong> The order value is the initial amount
          quoted to the client when they book the transportation service through
          the Instalorry app, or if any adjustments have been made to the
          initial quoted amount after consultation with the client and or
          driver, the adjusted amount.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>5. Account Creation</strong>
        </h3>
        <p>
          A user is required to create an account with accurate and up-to-date
          information to access the services.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>6. Service Usage</strong>
        </h3>
        <p>
          A driver agrees to transport goods safely and responsibly. A customer
          agrees to provide accurate information about the goods for
          transportation.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>7. Payments</strong>
        </h3>
        <p>
          <strong>7.1. Once-Off Clients:</strong> To accept orders from once-off
          clients, drivers must ensure their wallet on the Platform is topped up
          with sufficient funds. This wallet is used to pay the commission
          required to accept orders. The commission is equivalent to a
          percentage (to be determined in the Instalorry policy) of the order
          value (quoted amount to the client). Payment arrangements for the
          truck delivery service will be made directly between the driver and
          the client.
        </p>
        <br></br>

        <p>
          <strong>7.2. Contract Clients:</strong> For contract clients, a driver
          can accept the order regardless of their wallet balance. Accepting an
          order from a contract client does not affect the wallet balance. Once
          orders from contract clients have been executed, the contract client
          will pay Instalorry directly, and at the end of a cycle stipulated in
          the Instalorry policy, a payment will be made to the driver for the
          orders executed on behalf of the contract client. The value will be
          net of the commission charged by Instalorry, which is the equivalent
          of a percentage (to be determined in the Instalorry policy) of the
          order value.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>8. Liability and indemnity</strong>
        </h3>
        <p>
          <strong>8.1. General Liability:</strong>Instalorry, trading as
          Instalorry (Pty) Ltd, serves as a platform to connect a driver and a
          customer. While we strive to facilitate safe and reliable
          transportation services, Instalorry shall not be held liable for any
          damages, losses, or injuries resulting from the actions or negligence
          of a driver. This limitation of liability extends to the fullest
          extent permitted by law.
        </p>
        <br></br>

        <p>
          <strong>8.2. Insurance:</strong>Instalorry does not provide insurance
          for the goods transported. It is the responsibility of the customer to
          ensure that adequate insurance coverage is in place for their goods
          during transportation. Clients are advised to take appropriate
          measures to insure their goods, either through their own insurance
          provider or by purchasing additional coverage.
        </p>
        <br></br>

        <p>
          <strong>8.3. Once-Off Clients and Contract Clients:</strong>Instalorry
          acts as an agent providing the app platform to facilitate the
          connection between the driver and the customer. Although Instalorry
          receives payments and processes commissions, both once-off clients and
          contract clients agree to indemnify and hold Instalorry harmless from
          any claims arising out of or related to the transportation services
          provided by the driver. This includes any damages, losses, or injuries
          that may occur during the transportation service.
        </p>
        <br></br>

        <p>
          <strong>8.4. Indemnity Provision:</strong>In the event of a dispute
          arising from loss or damage to goods during the transportation
          service, Instalorry (Pty) Ltd may indemnify the client to the extent
          of the order value, which includes forfeiting the charge to the client
          (inclusive of Instalorry's commission). This indemnification will be
          subject to careful consultation with both the client and the driver
          involved in the order. Instalorry reserves the right to make the final
          decision on such indemnification on a case-by-case basis.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>9. User Conduct</strong>
        </h3>
        <p>
          A user agrees to use the app responsibly and not engage in any
          illegal, harmful, or disruptive activities.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>10. Privacy</strong>
        </h3>
        <p>
          Instalorry collects and stores user data as outlined in the privacy
          policy, in compliance with the Protection of Personal Information Act
          (POPIA). Please click the link below to read our privacy policy:
        </p>
        <div>
          <NavLink
            to={"/privacy"}
            className=""
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <strong>Privacy Policy</strong>
          </NavLink>
        </div>

        <br></br>

        <h3 className="subtitle">
          <strong>11. Intellectual Property</strong>
        </h3>
        <p>
          The content and intellectual property of the Instalorry app belong to
          Instalorry (Pty) Ltd. A user agrees not to use, reproduce, or modify
          the content without permission.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>12. Termination</strong>
        </h3>
        <p>
          Instalorry reserves the right to suspend or terminate a user account
          for violation of terms or any reason deemed necessary.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>13. Modifications</strong>
        </h3>
        <p>
          Instalorry (Pty) Ltd can update these terms and conditions at any
          time. A user will be notified of any changes.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>14. Dispute Resolution</strong>
        </h3>
        <p>
          Any disputes will be resolved through arbitration in accordance with
          local laws.
        </p>
        <br></br>

        <h3 className="subtitle">
          <strong>15. Governing Law</strong>
        </h3>
        <p>These terms are governed by the laws of South Africa.</p>
      </div>
      <br></br>

      <div>
        <button onClick={goBack} className="button is-success">
          Back
        </button>{" "}
        {/**to help navigate to the previous page after reading the terms and conditions */}
      </div>
    </section>
  );
};

export default TsCsComp;
