import React from "react";
import { useState, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { getToken } from "../../features/tokenSlice";
import { useSelector, useDispatch } from "react-redux";

const AddPricingForm = () => {
  const [truckSize, setTruckSize] = useState("");
  const [baseAmount, setBaseAmount] = useState("");
  const [ratePerKm, setRatePerKm] = useState("");
  const [helpersPricing, setHelpersPricing] = useState("");
  const [floorsPricing, setFloorsPricing] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(""); // we will display this message if there an error

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const savePrice = async (e) => {
    e.preventDefault(); // we prevent default so that when we submit the page it doesn't reload
    setIsLoading(true);

    try {
      const response = await axios.post(
        "/api/pricing/create",
        {
          truckSize, // same as: truckSize: truckSize
          baseAmount,
          ratePerKm,
          helpersPricing,
          floorsPricing,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(response.data.msg);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
      }
    } finally {
      setIsLoading(false); // Set loading back to false after the API call completes
    }
  };

  return (
    <div>
      <div>
        <h1 className="title">Prices</h1>
        <h1 className="subtitle">Add a vehicle pricing</h1>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="card is-shadowless">
              <div className="card-content">
                <div className="content">
                  <form onSubmit={savePrice}>
                    <div className="field">
                      <label className="label">Truck Size</label>
                      <div className="control">
                        <input
                          className="input"
                          value={truckSize}
                          onChange={(e) => setTruckSize(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Base Amount</label>
                      <div className="control">
                        <input
                          type="number"
                          className="input"
                          value={baseAmount}
                          onChange={(e) => setBaseAmount(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Rate Per Km</label>
                      <div className="control">
                        <input
                          className="input"
                          type="number" // to accept decimals, change to text then include some logic to handle this
                          value={ratePerKm}
                          onChange={(e) => setRatePerKm(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Price Per Helper</label>
                      <div className="control">
                        <input
                          className="input"
                          type="number"
                          value={helpersPricing}
                          onChange={(e) => setHelpersPricing(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Price Per Floor</label>
                      <div className="control">
                        <input
                          className="input"
                          type="number"
                          value={floorsPricing}
                          onChange={(e) => setFloorsPricing(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <p className="has-text-centered has-text-weight-bold">
                      {msg}
                    </p>

                    <div className="field">
                      <div className="control">
                        <button
                          disabled={isLoading}
                          type="submit"
                          className="button is-success is-fullwidth"
                        >
                          {isLoading ? "Loading..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPricingForm;
