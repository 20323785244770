import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getToken } from "../../features/tokenSlice";

const AddUserForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState(""); // we will display this message if there an error

  const dispatch = useDispatch();
  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const saveUser = async (e) => {
    e.preventDefault(); // we prevent default so that when we submit the page it doesn't reload

    try {
      const response = await axios.post(
        "/api/users/register",
        {
          name: name, // this is the data that we want to post
          // for name we attach the name from the state
          surname: surname,
          email: email,
          password: password,
          confPassword: confPassword,
          cellphone: cellphone,
          role: role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(response.data.msg);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
      }
    }
  };

  return (
    <div>
      <div>
        <h1 className="title">Administrators</h1>
        <h1 className="subtitle">Add administrator</h1>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="card is-shadowless">
              <div className="card-content">
                <div className="content">
                  <form onSubmit={saveUser}>
                    <div className="field">
                      <label className="label">Name</label>
                      <div className="control">
                        <input
                          className="input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Surname</label>
                      <div className="control">
                        <input
                          className="input"
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input
                          className="input"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Password</label>
                      <div className="control">
                        <input
                          className="input"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Confirm Password</label>
                      <div className="control">
                        <input
                          className="input"
                          type="password"
                          value={confPassword}
                          onChange={(e) => setConfPassword(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Cellphone</label>
                      <div className="control">
                        <input
                          className="input"
                          value={cellphone}
                          onChange={(e) => setCellphone(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Role</label>
                      <div className="control">
                        <span className="select is-fullwidth">
                          <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                          >
                            <option value="pleaseselect">Please select</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                          </select>
                        </span>
                      </div>
                    </div>

                    <p className="has-text-centered has-text-weight-bold">
                      {msg}
                    </p>

                    <div className="field">
                      <div className="control">
                        <button
                          type="submit"
                          className="button is-success is-fullwidth"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
