import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  truckSize: "",
  orderDate: "",
  orderTime: "",
  pickup: "",
  destination: "",
  pickupPlace: "",
  destinationPlace: "",
  kms: 0,
  floors: 0,
  helpers: 0,
  notes: "",
  baseCost: 0,
  distanceCost: 0,
  helpersCost: 0,
  floorsCost: 0,
  midmonthDiscount: 0,
  repeatDiscount: 0,
  promotionDiscount: 0,
  price: 0,
  commission: 0,
  net: 0,
  paymentType: "",
  payout: "",
  agreedToTerms: "",
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setTruckSize: (state, action) => {
      const truckSizeValue =
        action.payload === "pleaseSelect" ? "" : action.payload;
      state.truckSize = truckSizeValue; // normally we would write this like: state.truckSize = action.payload
      // we add the truckSizeValue function because on the given options in the truckSize field, when we click 'please select' react recognises that as state and therefore state
      // therefore we add the condition so that if that is selected, the state is modified to an empty string
      // we can now revert this to the normal setup like all the other actions since we removed 'please select' option
    },
    setOrderDate: (state, action) => {
      state.orderDate = action.payload;
    },
    setOrderTime: (state, action) => {
      state.orderTime = action.payload;
    },
    setPickup: (state, action) => {
      state.pickup = action.payload;
    },
    setDestination: (state, action) => {
      state.destination = action.payload;
    },
    setPickupPlace: (state, action) => {
      state.pickupPlace = action.payload;
    },
    setDestinationPlace: (state, action) => {
      state.destinationPlace = action.payload;
    },
    setKms: (state, action) => {
      state.kms = action.payload;
    },
    setFloors: (state, action) => {
      const floortsValue =
        action.payload === "pleaseSelect" ? "" : action.payload;
      state.floors = floortsValue;
    },
    setHelpers: (state, action) => {
      const helpersValue =
        action.payload === "pleaseSelect" ? "" : action.payload;
      state.helpers = helpersValue;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    setBaseCost: (state, action) => {
      state.baseCost = action.payload;
    },
    setDistanceCost: (state, action) => {
      state.distanceCost = action.payload;
    },
    setHelpersCost: (state, action) => {
      state.helpersCost = action.payload;
    },
    setFloorsCost: (state, action) => {
      state.floorsCost = action.payload;
    },
    setMidmonthDiscount: (state, action) => {
      state.midmonthDiscount = action.payload;
    },
    setRepeatDiscount: (state, action) => {
      state.repeatDiscount = action.payload;
    },
    setPromotionDiscount: (state, action) => {
      state.promotionDiscount = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setCommission: (state, action) => {
      state.commission = action.payload;
    },
    setNet: (state, action) => {
      state.net = action.payload;
    },
    setPaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setPayout: (state, action) => {
      state.price = action.payload;
    },
    setAgreedToTerms: (state, action) => {
      state.agreedToTerms = action.payload;
    },
    calculateQuote: (state, action) => {
      const { kms, truckSize, helpers, floors, orderDate } = state;
      const { pricing } = action.payload;

      if (pricing && (kms || kms === 0) && truckSize && orderDate) {
        const calculatedPrice =
          pricing.baseAmount +
          Math.ceil(parseFloat(kms) * pricing.ratePerKm) +
          Math.ceil(parseFloat(helpers) * pricing.helpersPricing) +
          Math.ceil(parseFloat(floors) * pricing.floorsPricing);

        // Apply midmonth discount
        const orderDateObj = new Date(orderDate);
        const dayOfMonth = orderDateObj.getDate();
        const monthDays = new Date(
          orderDateObj.getFullYear(),
          orderDateObj.getMonth() + 1,
          0
        ).getDate();

        const isMidMonth =
          dayOfMonth !== 1 &&
          dayOfMonth !== monthDays &&
          dayOfMonth !== monthDays - 1;
        const middiscount = isMidMonth
          ? Math.floor(calculatedPrice * 0.05) * -1
          : 0;

        // Apply promitional discount
        const promdiscount = Math.floor(calculatedPrice * 0.1) * -1;

        // Apply repeat discount
        const repdiscount = 0;

        state.baseCost = pricing.baseAmount;
        state.distanceCost = Math.ceil(parseFloat(kms) * pricing.ratePerKm);
        state.helpersCost = Math.ceil(
          parseFloat(helpers) * pricing.helpersPricing
        );
        state.floorsCost = Math.ceil(
          parseFloat(floors) * pricing.floorsPricing
        );
        state.midmonthDiscount = middiscount;
        state.promotionDiscount = promdiscount;
        state.repeatDiscount = repdiscount;
        state.price =
          calculatedPrice + middiscount + promdiscount + repdiscount;
      }
    },
  },
});

export const {
  setTruckSize,
  setOrderDate,
  setOrderTime,
  setPickup,
  setDestination,
  setPickupPlace,
  setDestinationPlace,
  setKms,
  setFloors,
  setHelpers,
  setNotes,
  setBaseCost,
  setDistanceCost,
  setHelpersCost,
  setFloorsCost,
  setMidmonthDiscount,
  setRepeatDiscount,
  setPromotionDiscount,
  setPrice,
  setCommission,
  setNet,
  setPaymentType,
  setPayout,
  setAgreedToTerms,
  calculateQuote,
} = orderSlice.actions;

export default orderSlice.reducer;
