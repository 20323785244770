import React from "react";
import DriverEmailVerForm from "../../components/drivers/DriverEmailVerForm";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMe } from "../../features/authSlice";

const DriverEmailVer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <Layout>
      <DriverEmailVerForm></DriverEmailVerForm>
    </Layout>
  );
};

export default DriverEmailVer;
