import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  truckSize: "",
  licencePlate: "",
  shape: "",
  git: "",
  agreedToTerms: "",
};

const vehicleOnlyRegSlice = createSlice({
  name: "regVehicleOnly",
  initialState,
  reducers: {
    setTruckSize: (state, action) => {
      state.truckSize = action.payload;
    },
    setLicencePlate: (state, action) => {
      state.licencePlate = action.payload;
    },
    setShape: (state, action) => {
      state.shape = action.payload;
    },
    setGit: (state, action) => {
      state.git = action.payload;
    },
    setAgreedToTerms: (state, action) => {
      state.agreedToTerms = action.payload;
    },
  },
});

export const {
  setTruckSize,
  setLicencePlate,
  setShape,
  setGit,
  setAgreedToTerms,
} = vehicleOnlyRegSlice.actions;

export default vehicleOnlyRegSlice.reducer;
