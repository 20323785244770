import React from "react";
// import backgroundUndrawImage from "../../assets/undraw_heavy_box.png";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();

  const navigateToQuote = () => {
    navigate("/quote");
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="columns">
        <div className="column is-5 has-text-centered">
          <div className="card box blue-background-color">
            <div className="card-content">
              <div className="content is-size-4">
                <p className="has-text-white has-text-weight-bold">
                  Get an instant, obligation-free, online moving or delivery
                  transportation quote
                </p>
              </div>
            </div>
          </div>
          <button
            className="button blue-background-color has-text-white is-rounded has-text-weight-bold is-medium"
            onClick={navigateToQuote}
          >
            Click here to start
          </button>
          {/* <div className="card has-text-centered">
            <Link
              to="/services"
              className="card-footer-item has-text-white"
              onClick={() => {
                // to ensure you end up at the top of the page when going to link
                window.scroll(0, 0);
              }}
            >
              Click here for more info
            </Link>
            <div className="card-content">
              <div className="content">
                <button className="button blue-background-color has-text-white">
                  Click
                </button>
              </div>
            </div>
          </div> */}
          {/* <div className="columns">
            <div className="column">
              <button
                className="button blue-background-color has-text-white is-rounded"
                onClick={navigateToQuote}
              >
                Click here
              </button>
            </div>
          </div> */}

          {/* <div
            className="card box"
            // style={{
            //   backgroundImage: "url('../../assets/undraw_heavy_box.png')",
            // }}
            style={{
              backgroundImage: `url(${backgroundUndrawImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          > */}
          {/* Overlay for grey-ish effect */}
          {/* <div className="card-overlay"></div>
            <p className="card-header-title is-size-4 welcome-text">
              Moving and Business Logistics Company
            </p>
            <div className="card-content">
              <div className="content welcome-text is-size-5">
                <p>
                  Welcome to Instalorry, an online platform where you can get
                  affordable, good quality, professional and reliable
                  transportation service.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
