import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  setName,
  setSurname,
  setEmail,
  setPassword,
  setConfPassword,
  setCellphone,
  setArea,
  setAgreedToTerms,
} from "../../features/driverRegSlice";

const AddDriverOnlyForm = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  //   const navigate = useNavigate();

  const {
    name,
    surname,
    email,
    password,
    confPassword,
    cellphone,
    area,
    agreedToTerms,
  } = useSelector((state) => state.regDriver);

  // frontend fields validation for completeness
  const validateFields = () => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!surname) {
      errors.surname = "Surname is required";
    }
    if (!email) {
      errors.email = "Email is required";
    }
    if (!password) {
      errors.password = "Password is required";
    }
    if (!confPassword) {
      errors.confPassword = "Confirm Password is required";
    }
    if (!cellphone) {
      errors.cellphone = "Cellphone is required";
    }
    if (!area) {
      errors.area = "Area based is required";
    }
    if (!agreedToTerms) {
      errors.agreedToTerms = "Please agree to the Terms and Conditions";
    }

    if (
      !name ||
      !surname ||
      !email ||
      !password ||
      !confPassword ||
      !cellphone ||
      !area ||
      !agreedToTerms
    ) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  const saveDriver = async (e) => {
    // e.preventDefault();  // this is removed since the submission is triggered by the yes button outside the form
    // letting this stay here causes errors
    setIsLoading(true);
    // validate the completeness of the fields
    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      closeModal();
      return;
    }
    try {
      const response = await axios.post("/api/drivers/register/driveronly", {
        name: name, // this is the data that we want to post
        // for name we attach the name from the state
        surname: surname,
        email: email,
        password: password,
        confPassword: confPassword,
        cellphone: cellphone,
        area: area,
        ownerDriver: "no",
      });
      setMsg(response.data.msg);
      //   navigate("/clients/login");
      //   alert(response.data.msg);

      //   navigate("/clients/verify") // after receiving client verification email
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
        alert(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  function openModal(e) {
    e.preventDefault(); // putting this here because the function button triggering this is in the form.
    // this causes the form to render and refresh the page causing the modal to close
    setIsModalOpen("is-active");
  }

  function closeModal() {
    setIsModalOpen("");
  }

  return (
    <div>
      <div>
        <h1 className="title has-text-centered has-text-weight-bold">
          Drivers
        </h1>
        <h1 className="subtitle has-text-centered">Become a driver</h1>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <form>
                    <div className="field">
                      <label className="label">Name</label>
                      <div className="control">
                        <input
                          className={`input ${errors.name ? "is-danger" : ""}`}
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                        ></input>
                      </div>
                      {errors.name && (
                        <p className="help is-danger">{errors.name}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Surname</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.surname ? "is-danger" : ""
                          }`}
                          value={surname}
                          onChange={(e) => dispatch(setSurname(e.target.value))}
                        ></input>
                      </div>
                      {errors.surname && (
                        <p className="help is-danger">{errors.surname}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input
                          className={`input ${errors.email ? "is-danger" : ""}`}
                          type="email"
                          value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                        ></input>
                      </div>
                      {errors.email && (
                        <p className="help is-danger">{errors.email}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Password</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.password ? "is-danger" : ""
                          }`}
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) =>
                            dispatch(setPassword(e.target.value))
                          }
                        ></input>
                        <button
                          type="button"
                          className="button is-green is-small is-primary mt-1"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? "👁️" : "👁️‍🗨️"}
                        </button>
                      </div>
                      {errors.password && (
                        <p className="help is-danger">{errors.password}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Confirm Password</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.confPassword ? "is-danger" : ""
                          }`}
                          type={showPassword ? "text" : "password"}
                          value={confPassword}
                          onChange={(e) =>
                            dispatch(setConfPassword(e.target.value))
                          }
                        ></input>
                        <button
                          type="button"
                          className="button is-green is-small is-primary mt-1"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? "👁️" : "👁️‍🗨️"}
                        </button>
                      </div>
                      {errors.confPassword && (
                        <p className="help is-danger">{errors.confPassword}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Cellphone</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.cellphone ? "is-danger" : ""
                          }`}
                          value={cellphone}
                          onChange={(e) =>
                            dispatch(setCellphone(e.target.value))
                          }
                        ></input>
                      </div>
                      {errors.cellphone && (
                        <p className="help is-danger">{errors.cellphone}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Area based</label>
                      <div className="control">
                        <input
                          className={`input ${errors.area ? "is-danger" : ""}`}
                          value={area}
                          onChange={(e) => dispatch(setArea(e.target.value))}
                        ></input>
                      </div>
                      {errors.area && (
                        <p className="help is-danger">{errors.area}</p>
                      )}
                    </div>

                    <div className="field">
                      <div className="control">
                        <label className="checkbox">
                          <input
                            type={`checkbox`}
                            checked={agreedToTerms}
                            onChange={() =>
                              dispatch(setAgreedToTerms(!agreedToTerms))
                            }
                          />{" "}
                          {/**add space*/}I agree to the
                          <NavLink
                            to="/terms"
                            onClick={() => {
                              // to ensure you end up at the top of the page when going to link
                              window.scroll(0, 0);
                            }}
                          >
                            {" "}
                            Terms and Conditions
                          </NavLink>
                        </label>
                      </div>
                      {errors.agreedToTerms && (
                        <p className="help is-danger">{errors.agreedToTerms}</p>
                      )}
                    </div>

                    <p className="has-text-centered has-text-weight-bold">
                      {msg}
                    </p>

                    {/**frontend completeness validation oeverall message */}
                    {overallError && (
                      <p className="has-text-centered has-text-danger">
                        {overallError}
                      </p>
                    )}

                    <div className="field">
                      <div className="control">
                        <button
                          onClick={openModal}
                          className="button is-success is-fullwidth"
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    {/* {name &&
                    surname &&
                    email &&
                    password &&
                    confPassword &&
                    cellphone &&
                    agreedToTerms ? (
                      <div className="field">
                        <div className="control">
                          <button
                            onClick={openModal}
                            className="button is-success is-fullwidth"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ) : null} */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**Modal */}
      <div
        className={`modal ${isModalOpen === "is-active" ? "is-active" : ""}`}
      >
        <div className="modal-background"></div>
        <div className="modal-content">
          <article className="message is-warning is-size-5 is-half-mobile">
            <div className="message-header">
              <p>{`You will be sent a verification code. Is the following email correct: ${email}`}</p>
            </div>
            <div className="message-body is-flex is-justify-content-between">
              <div className="is-flex-grow-1">
                <button
                  disabled={isLoading}
                  onClick={() => saveDriver()}
                  className="button is-small is-success is-size-6"
                >
                  {isLoading ? "Loading..." : "Yes"}
                </button>
              </div>
              <div>
                <button
                  onClick={closeModal}
                  className="button is-small is-success is-size-6 is-end"
                >
                  Change email
                </button>
              </div>
            </div>
          </article>
        </div>
        <button
          onClick={closeModal}
          className="modal-close is-large"
          aria-label="close"
        ></button>
      </div>
    </div>
  );
};

export default AddDriverOnlyForm;
