import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  party: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const Login = createAsyncThunk(
  "parties/Login",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post("/api/login", {
        email: user.email,
        password: user.password,
        loginPartyType: user.loginPartyType,
      });
      const { token, partyData } = response.data;
      // Store token in cookie or local storage
      document.cookie = `instalorry_token=${token}; path=/`; // change the name of the token to a unique identifier
      console.log(token);
      return partyData;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const getMe = createAsyncThunk("parties/getMe", async (_, thunkAPI) => {
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const token = getCookie("instalorry_token");
  console.log("getMe token jwt:", token);
  try {
    const response = await axios.get("/api/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.message) {
      const message = error.response.data.msg;
      return thunkAPI.rejectWithValue(message);
    }
  }
});

export const logout = () => async (dispatch) => {
  try {
    // Clear token from document cookie
    document.cookie =
      "instalorry_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  } catch (error) {
    console.error("Error logging out:", error);
    // Handle error if necessary
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.party = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    // login function
    builder.addCase(Login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(Login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false; // refer to react native version as to why this is done
      state.party = action.payload;
    });
    builder.addCase(Login.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    // get user
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.party = action.payload;
    });
    builder.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
