import React from "react";
import ClientList from "../../components/clients/ClientList";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const Clients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, party } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/clients/login");
    } else if (party && party.type !== "user") {
      // note (1)
      navigate("/dashboard");
    }
  }, [party, navigate, isError]);

  return (
    <Layout>
      <ClientList></ClientList>
    </Layout>
  );
};

export default Clients;
