import React from "react";
import TestComp from "../components/TestComp";

const Test = () => {
  return (
    <div>
      <TestComp></TestComp>
    </div>
  );
};

export default Test;
