import React from "react";
import Layout from "../general/Layout";
import AddDriverForm from "../../components/drivers/AddDriverForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const AddDriver = () => {
  const dispatch = useDispatch();
  const { verifiedEmail, email } = useSelector((state) => state.regDriver);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    // <Layout>
    //   <AddDriverForm></AddDriverForm>
    // </Layout>
    <>
      {verifiedEmail && email ? (
        <Layout>
          <AddDriverForm />
        </Layout>
      ) : (
        <Navigate to="/drivers/register" />
      )}
    </>
  );
};

export default AddDriver;
