import React from "react";
import { useSelector } from "react-redux";

const DashboardComp = () => {
  const { party } = useSelector((store) => store.auth);

  return (
    <div>
      <section className="section">
        <div className="container">
          <h1 className="title has-text-centered section-header">Dashboard</h1>
          <div className="card box">
            <div className="card-content">
              <div className="content">
                <p className="is-size-4">
                  Welcome back <strong>{party && party.name}</strong>
                </p>
                <p className="is-size-4">
                  You are now logged in as{" "}
                  <strong>{party && party.type}</strong>
                </p>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      </section>
    </div>
  );
};

export default DashboardComp;
