import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PriceBreakdownComp = () => {
  const navigate = useNavigate();
  const {
    baseCost,
    distanceCost,
    helpersCost,
    floorsCost,
    midmonthDiscount,
    // repeatDiscount,
    promotionDiscount,
    price,
  } = useSelector((store) => store.order);

  const handleNext = () => {
    navigate("/orders/terms");
    window.scroll(0, 0);
  };

  const handleBack = () => {
    navigate("/quote");
  };

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <div className="box">
              <h1 className="title has-text-centered section-header">
                Quote Breakdown
              </h1>
              <div className="content">
                <table className="table is-vertical">
                  <tbody>
                    <tr>
                      <th>Base Cost:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold">
                          R{baseCost}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Distance Cost:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold">
                          R{distanceCost}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Helpers Cost:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold">
                          R{helpersCost}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Floors Cost:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold">
                          R{floorsCost}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Midmonth Discount:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold">
                          R{midmonthDiscount}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Promotional Discount:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold">
                          R{promotionDiscount}
                        </span>
                      </td>
                    </tr>
                    {/* {repeatDiscount && repeatDiscount !== 0 && (
                      <tr>
                        <th>Repeat Discount:</th>
                        <td>
                          <span className="has-text-primary has-text-weight-bold is-size-5">
                            R{repeatDiscount}
                          </span>
                        </td>
                      </tr>
                    )} */}

                    {/* <tr>
                      <th colSpan="2">
                        <hr />
                      </th>
                    </tr> */}
                    <tr>
                      <th>Total Price:</th>
                      <td>
                        <span className="has-text-primary has-text-weight-bold is-size-5">
                          R{price}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="buttons is-centered">
                <button
                  className="button blue-background-color has-text-white is-rounded"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="button blue-background-color has-text-white is-rounded"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceBreakdownComp;
