import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";
import ConfirmationModal from "../general/ConfirmationModal";

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const dispatch = useDispatch();

  const { party } = useSelector((store) => store.auth);
  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getOrders = useCallback(async () => {
    try {
      const response = await axios.get("/api/orders/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrders(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getOrders(); // Call getUsers immediately after defining it
  }, [getOrders]);
  // not necessary to add getUsers as a dependency
  // this is because getUsers does not depend on this dependency to execute
  // this is defined in the useCallback function

  const deleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(`api/orders/delete/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.msg);
    } catch (error) {
      alert(error.response.data.msg);
    }
    getOrders();
  };

  const openModal = (orderId) => {
    setOrderToDelete(orderId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOrderToDelete(null);
  };

  const confirmDelete = () => {
    if (orderToDelete !== null) {
      deleteOrder(orderToDelete);
    }
    closeModal();
  };

  return (
    <div>
      <h1 className="title has-text-centered section-header">Orders</h1>
      <h2 className="subtitle has-text-centered blue-font-color">
        List of orders
      </h2>

      <div className="table-container">
        {" "}
        {/* Apply responsive container */}
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              {party && party.type === "user" && <th>No</th>}

              <th>Id</th>
              <th>Order Date</th>
              <th>Order Time</th>

              {party && party.type === "user" && (
                <>
                  <th>Client Id</th>
                  <th>Truck Size</th>
                  <th>Pickup</th>
                  <th>Destination</th>
                  <th>Kms</th>
                  <th>Helpers</th>
                  <th>Floors</th>
                  <th>Price</th>
                  <th>Commission</th>
                  <th>Net</th>
                  <th>Notes</th>
                  <th>Order Type</th>
                  <th>Vehicle Id</th>
                  <th>Driver Id</th>
                </>
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={order.id}>
                {party && party.type === "user" && <td>{index + 1}</td>}

                <td>{order.id}</td>
                <td>{order.orderDate}</td>
                <td>{order.orderTime}</td>
                {party && party.type === "user" && (
                  <>
                    <td>{order.clientId}</td>
                    <td>{order.truckSize}</td>
                    <td>{order.pickup}</td>
                    <td>{order.destination}</td>
                    <td>{order.kms}</td>
                    <td>{order.helpers}</td>
                    <td>{order.floors}</td>
                    <td>{order.price}</td>
                    <td>{order.commission}</td>
                    <td>{order.net}</td>
                    <td>{order.notes}</td>
                    <td>{order.orderType}</td>
                    <td>{order.vehicleId}</td>
                    <td>{order.driverId}</td>
                  </>
                )}

                <td>
                  <Link
                    to={`/orders/details/${order.id}`}
                    className="button is-small is-info"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Order details
                  </Link>
                  {party && party.type === "user" && (
                    <button
                      onClick={() => openModal(order.id)}
                      className="button is-small is-danger"
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        onConfirm={confirmDelete}
        onCancel={closeModal}
        message="Are you sure you want to delete this order?"
      />
    </div>
  );
};

export default OrderList;
