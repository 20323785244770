import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment"; // Import the moment library
import { getToken } from "../../features/tokenSlice";

const OrderDetailsComp = () => {
  const [truckSize, setTruckSize] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [orderTime, setOrderTime] = useState("");
  const [pickup, setPickup] = useState("");
  const [destination, setDestination] = useState("");
  const [kms, setKms] = useState("");
  const [helpers, setHelpers] = useState("");
  const [floors, setFloors] = useState("");
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState("");
  const [net, setNet] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [orderType, setOrderType] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [clientCellphone, setClientCellphone] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [driverId, setDriverId] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverSurname, setDriverSurname] = useState("");
  const [driverCellphone, setDriverCellphone] = useState("");
  const [driverEmail, setDriverEmail] = useState("");
  const [driverSelected, setDriverSelected] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [driverError, setDriverError] = useState(""); // Error message for driver selection
  // const [overallError, setOverallError] = useState(""); // Single overall error message

  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  const { id } = useParams();

  const { token } = useSelector((store) => store.tokenReducer);
  const { party } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getOrderById = useCallback(async () => {
    try {
      const response = await axios.get(`/api/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTruckSize(response.data.truckSize);
      setOrderDate(response.data.orderDate);
      setOrderTime(response.data.orderTime);
      setPickup(response.data.pickup);
      setDestination(response.data.destination);
      setKms(response.data.kms);
      setHelpers(response.data.helpers);
      setFloors(response.data.floors);
      setPrice(response.data.price);
      setCommission(response.data.commission);
      setNet(response.data.net);
      setNotes(response.data.notes);
      setStatus(response.data.status);
      setPaymentType(response.data.paymentType);
      setOrderType(response.data.orderType);
      setDriverId(response.data.driverId);

      if (driverId) {
        // doing this because in the driver id field you cannot pass null as value rather an empty string ""
        // however, the dababase does not allow you to send empty string as value, you need null
        // the solution to to do this
        setDriverName(response.data.driver.name);
        setDriverSurname(response.data.driver.surname);
        setDriverCellphone(response.data.driver.cellphone);
        setDriverEmail(response.data.driver.email);
      }

      setClientId(response.data.clientId);
      setClientName(response.data.client.name);
      setClientSurname(response.data.client.surname);
      setClientCellphone(response.data.client.cellphone);
      setClientEmail(response.data.client.email);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.msg);
      }
    }
  }, [driverId, id, token]);

  // driverId, token, id, status

  useEffect(() => {
    // see above on the conditional running of the driver info
    // because we now set the driver info to the response once driverId is true, that means the response has not yet returned the state of driver info
    // therefore, we need an additional render (fetch data again) and this time set the driver info to that response
    // this render happens once the driverId is true
    getOrderById();
  }, [getOrderById]);

  const getDrivers = useCallback(async () => {
    const response = await axios.get("/api/drivers/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setDrivers(response.data);
  }, [token]);
  // might include drivers aS A DEPENDENCY

  useEffect(() => {
    if (party && party.type === "user") {
      getDrivers();
    }
  }, [getDrivers, party]);

  // this is used to assign/unassign driver on order - by user
  // const updateOrderByUser = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   try {
  //     // Handle the case for "No driver"
  //     let updatedDriver = null; // doing this because in the driver id field you cannot pass null as value rather an empty string ""
  //     let updatedVehicle = null;
  //     if (Object.keys(driverSelected).length !== 0) {
  //       // Only set updatedDriver if driverSelected is not empty
  //       updatedDriver = driverSelected.selectedDriverId;
  //       updatedVehicle = driverSelected.selectedVehicleId;
  //     }

  //     await axios.patch(
  //       `/api/orders/assign/${id}`,
  //       {
  //         driverId: updatedDriver,
  //         vehicleId: updatedVehicle,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     // navigate("/orders")

  //     getOrderById(); // run again to fetch from the db the latest state of driverId after update
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response.data.msg);
  //     }
  //   } finally {
  //     setIsLoading(false); // Set loading back to false after the API call completes
  //   }
  // };

  const bookOrder = async () => {
    setIsLoading(true);

    try {
      let updatedDriver = null;
      let updatedVehicle = null;
      if (Object.keys(driverSelected).length !== 0) {
        updatedDriver = driverSelected.selectedDriverId;
        updatedVehicle = driverSelected.selectedVehicleId;
      } else {
        setDriverError("Error: Please select a driver");
        setIsLoading(false);
        return;
      }

      await axios.patch(
        `/api/orders/assign/${id}`,
        {
          driverId: updatedDriver,
          vehicleId: updatedVehicle,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      getOrderById();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const cancelBooking = async () => {
    setIsLoading(true);

    try {
      await axios.patch(
        `/api/orders/unassign/${id}`,
        {
          driverId: null,
          vehicleId: null,
          removedDriverId: driverId, // to use on the driverAccount, each tx needs a driverId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      getOrderById();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const startCompleteOrder = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let updatedStatus;

      if (status === "pending") {
        updatedStatus = "started";
      } else if (status === "started") {
        updatedStatus = "completed";
      }

      if (updatedStatus) {
        await axios.patch(
          `/api/orders/status/${id}`,
          {
            status: updatedStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (updatedStatus === "completed") {
        console.log("email sent");
        // await sendInvoiceEmail();
        // await sendSurveyEmail();
      }

      // navigate("/orders")
      getOrderById();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //   const sendInvoiceEmail = async () => {
  //     await axios.post("/orders/emailInvoice", {
  //       orderId: id,
  //       orderDate,
  //       orderTime,
  //       price,
  //       pickup,
  //       destination,
  //       clientName,
  //       clientSurname,
  //       clientEmail,
  //       driverName,
  //       driverSurname,
  //     });
  //   };

  //   const sendSurveyEmail = async () => {
  //     await axios.post("/orders/emailSurvey", {
  //       orderId: id,
  //       clientEmail,
  //       driverName,
  //       driverSurname,
  //     });
  //   };

  return (
    <div>
      <section className="section">
        <div className="container">
          <h1 className="title has-text-centered section-header">Orders</h1>
          <h2 className="subtitle has-text-centered blue-font-color">
            Order details
          </h2>
          <div className="columns is-centered">
            <div className="column is-8">
              <div className="card box">
                <div className="card-content">
                  <div className="content">
                    <table className="table is-vertical">
                      <tbody>
                        <tr>
                          <th>Order Id:</th>
                          <td>{id}</td>
                        </tr>
                        <tr>
                          <th>Date of move:</th>
                          <td>{moment(orderDate).format("DD.MM.YYYY")}</td>{" "}
                          {/* Format the date */}
                        </tr>
                        <tr>
                          <th>Time of move:</th>
                          <td>{orderTime}</td>
                        </tr>
                        <tr>
                          <th>Truck size:</th>
                          <td>{truckSize}</td>
                        </tr>
                        <tr>
                          <th>Pickup Address:</th>
                          <td>{pickup}</td>
                        </tr>
                        <tr>
                          <th>Destination Address:</th>
                          <td>{destination}</td>
                        </tr>
                        <tr>
                          <th>Distance in Kilometers:</th>
                          <td>{kms}</td>
                        </tr>
                        <tr>
                          <th>Number of helpers:</th>
                          <td>{helpers}</td>
                        </tr>
                        <tr>
                          <th>Total floors (pickup plus destination):</th>
                          <td>{floors}</td>
                        </tr>
                        <tr>
                          <th>Price:</th>
                          <td>
                            <strong style={{ color: "green" }}>R{price}</strong>
                          </td>
                        </tr>
                        {party && party.type === "user" && (
                          <>
                            <tr>
                              <th>Commission:</th>
                              <td>
                                <strong style={{ color: "green" }}>
                                  R{commission}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <th>Net Amount:</th>
                              <td>
                                <strong style={{ color: "green" }}>
                                  R{net}
                                </strong>
                              </td>
                            </tr>
                          </>
                        )}

                        <tr>
                          <th>Additional Notes:</th>
                          <td>{notes}</td>
                        </tr>
                        <tr>
                          <th>Payment type:</th>
                          <td>{paymentType}</td>
                        </tr>
                        <tr>
                          <th>Order type:</th>
                          <td>{orderType}</td>
                        </tr>
                        <tr>
                          <th>Client Id:</th>
                          <td>{clientId}</td>
                        </tr>
                        <tr>
                          <th>Client Name:</th>
                          <td>{clientName}</td>
                        </tr>
                        <tr>
                          <th>Client Surname:</th>
                          <td>{clientSurname}</td>
                        </tr>
                        <tr>
                          <th>Client Cellphone:</th>
                          <td>{clientCellphone}</td>
                        </tr>
                        <tr>
                          <th>Client Email:</th>
                          <td>{clientEmail}</td>
                        </tr>
                        <tr>
                          <th>Driver Id:</th>
                          <td>{driverId}</td>
                        </tr>
                        {driverId ? ( // the conditional rendering is to fix a bug. The client info does not appear when the driver fields are empty (except driverId I think because it has a heading on orders table)
                          // it appears that the component might be trying to access and display these fields even when the driverId is null, leading to unexpected behavior.
                          // there is really no logic to the fix, all we do is to stop the component from accessing these secondary data (drivers) when driverId is null to kill the bug
                          <>
                            <tr>
                              <th>Driver Name:</th>
                              <td>{driverName}</td>
                            </tr>
                            <tr>
                              <th>Driver Surname:</th>
                              <td>{driverSurname}</td>
                            </tr>
                            <tr>
                              <th>Driver Cellphone:</th>
                              <td>{driverCellphone}</td>
                            </tr>
                            <tr>
                              <th>Driver Email:</th>
                              <td>{driverEmail}</td>
                            </tr>
                          </>
                        ) : null}
                        <tr>
                          <th>Job Progress:</th>
                          <td>{status}</td>
                        </tr>
                      </tbody>
                    </table>
                    {party && party.type === "user" && (
                      <div>
                        {driverId === null && status !== "completed" && (
                          <div className="field">
                            <label className="label">Driver Selection</label>
                            <div className="control">
                              <div className="columns is-mobile">
                                <div className="column">
                                  {/* Dropdown list with options from drivers' data */}
                                  <select
                                    className="select"
                                    value={JSON.stringify(driverSelected)}
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;
                                      if (selectedValue === "") {
                                        setDriverSelected({}); // Reset to an empty object when "No driver" is selected
                                      } else {
                                        setDriverSelected(
                                          JSON.parse(selectedValue)
                                        );
                                      }
                                    }}
                                  >
                                    <option value="">Please select</option>
                                    {drivers.map((driver) => (
                                      <option
                                        key={driver.id}
                                        value={JSON.stringify({
                                          selectedDriverId: driver.id,
                                          selectedVehicleId: driver.vehicleId,
                                        })}
                                      >
                                        ({`id: ${driver.id}`}) {driver.name} (
                                        {`VehicleId: ${driver.vehicleId}`})
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="column">
                                  <button
                                    type="submit"
                                    onClick={bookOrder}
                                    className={`button is-success is-fullwidth`}
                                    disabled={isLoading} // Disable the button while loading
                                  >
                                    {isLoading ? "Loading..." : "Process"}
                                  </button>
                                  {driverError && (
                                    <p className="has-text-centered has-text-danger">
                                      {driverError}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {driverId && status !== "completed" ? (
                          <div className="field">
                            <div className="control">
                              <button
                                type="submit"
                                onClick={cancelBooking} // Call the cancelBooking function when the button is clicked
                                className={`button is-danger is-fullwidth`}
                                disabled={isLoading}
                              >
                                {isLoading ? "Loading..." : "Remove driver"}
                              </button>
                            </div>
                          </div>
                        ) : null}

                        {driverId && status !== "completed" ? (
                          <div className="field">
                            <div className="control">
                              <button
                                type="submit"
                                onClick={startCompleteOrder}
                                className="button is-success is-fullwidth"
                                disabled={isLoading}
                              >
                                {isLoading
                                  ? "Loading..."
                                  : status === "pending"
                                  ? "Start Order"
                                  : "Complete Order"}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderDetailsComp;
