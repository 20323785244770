import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [message, setMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const saveContact = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post("/api/createcontact", {
        name: name,
        email: email,
        cellphone: cellphone,
        message: message,
      });

      clearForm();
      setMsg(response.data.msg);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
        console.error("Error response:", error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setCellphone("");
    setMessage("");
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="has-text-centered">
            <h1 className="title has-text-weight-bold">Contact us</h1>
          </div>

          <div className="subtitle has-text-centered"></div>
          <div className="columns is-centered">
            <div className="column is-7">
              <div className="card box">
                <p className="card-header-title is-centered blue-font-color is-size-4">
                  Please feel free to reach out to us
                </p>
                <div className="card-content">
                  <div className="content">
                    <form onSubmit={saveContact}>
                      <div className="field">
                        <label className="label blue-font-color">Name</label>
                        <div className="control">
                          <input
                            className="input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          ></input>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">Email</label>
                        <div className="control">
                          <input
                            className="input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></input>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">
                          Cellphone number
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            value={cellphone}
                            onChange={(e) => setCellphone(e.target.value)}
                          ></input>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label blue-font-color">Message</label>
                        <div className="control">
                          <textarea
                            className="textarea"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="field">
                        <div className="control">
                          <button
                            disabled={isLoading}
                            type="submit"
                            className="button blue-background-color has-text-white is-rounded"
                          >
                            {isLoading ? "Loading..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                    {msg && <p>{msg}</p>}
                  </div>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card box">
                <p className="card-header-title is-centered blue-font-color is-size-4">
                  Contact details
                </p>
                <div className="card-content">
                  <div className="content">
                    <table className="table is-vertical has-text-centered is-centered">
                      <tbody>
                        <tr>
                          <th>Email:</th>
                          <td>info@instalorry.co.za</td>
                        </tr>
                        <tr>
                          <th>Contact number/ Whatsapp:</th>
                          <td>065 870 2019</td>
                        </tr>
                        <tr>
                          <th>Operating Days:</th>
                          <td>Any day of the week including holidays</td>
                        </tr>
                        <tr>
                          <th>Operating hours:</th>
                          <td>07:00 - 17:00</td>
                        </tr>
                        <tr>
                          <th>Address:</th>
                          <td>
                            Bank towers, 190 Thabo Sehume Street, Pretoria
                            Central, Pretoria
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
