import React from "react";
import HomePage from "../../components/general/HomeComp";
import Layout from "./Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMe } from "../../features/authSlice";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <Layout>
      <HomePage></HomePage>
    </Layout>
  );
};

export default Home;
