import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  loading: false, // Track the loading state
  error: null, // Track any errors
};

// Define a thunk to fetch the token asynchronously
export const getToken = createAsyncThunk("token/fetchToken", async () => {
  try {
    const token = await new Promise((resolve, reject) => {
      const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) resolve(parts.pop().split(";").shift());
        else reject("Cookie not found");
      };
      getCookie("instalorry_token");
    });
    return token;
  } catch (error) {
    throw error; // Throw the error to be handled by the caller
  }
});
// using the new Promise function because of warning:
// "await has no effect on this type of expression"

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    // Add additional reducers if needed
  },
  extraReducers: (builder) => {
    // Handle the pending state when the fetchToken thunk is dispatched
    builder.addCase(getToken.pending, (state) => {
      state.loading = true;
      state.error = null; // Clear any previous errors
    });
    // Handle the fulfilled state when the fetchToken thunk is resolved
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload; // Update the token in the state
    });
    // Handle the rejected state when the fetchToken thunk is rejected
    builder.addCase(getToken.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message; // Store the error message
    });
  },
});

export default tokenSlice.reducer;
