import React from "react";
import OrderList from "../../components/orders/OrderList";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/clients/login"); // go back to previous page
    }
  }, [navigate, isError]);

  return (
    <Layout>
      <OrderList></OrderList>
    </Layout>
  );
};

export default Orders;
