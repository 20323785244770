import React, { useEffect, useState, useCallback } from "react";

const TestComp = () => {
  const [count, setCount] = useState(0);

  console.log("render TestComp");

  const testFunction = useCallback(() => {
    console.log(count);
  }, [count]);

  useEffect(() => {
    testFunction();
  }, [testFunction]);

  const buttonClicked = () => {
    setCount(count + 1);
  };

  return (
    <div>
      TestComp
      <button onClick={buttonClicked}>Click me</button>
    </div>
  );
};

export default TestComp;
