import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";

const UpdateClientForm = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [status, setStatus] = useState("");
  const [clientType, setClientType] = useState("");
  const [corporateInterest, setCorporateInterest] = useState("");
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  useEffect(() => {
    const getClientById = async () => {
      try {
        const response = await axios.get(`api/clients/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setName(response.data.name);
        setSurname(response.data.surname);
        setEmail(response.data.email);
        setCellphone(response.data.cellphone);
        setStatus(response.data.status);
        setClientType(response.data.clientType);
        setCorporateInterest(response.data.corporateInterest);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getClientById();
  }, [id, token]);

  const updateClient = async (e) => {
    e.preventDefault();

    try {
      await axios.patch(
        `/api/clients/update/${id}`,
        {
          name: name,
          surname: surname,
          email: email,
          cellphone: cellphone,
          status: status,
          clientType: clientType,
          corporateInterest: corporateInterest,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/clients/all");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div>
        <h1 className="title">Clients</h1>
        <h2 className="subtitle">Update Client Information</h2>
        <div className="columns iscentered">
          <div className="column is-8">
            <div className="card-content">
              <div className="content">
                <form onSubmit={updateClient}>
                  <p>{msg}</p>

                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">surname</label>
                    <div className="control">
                      <input
                        className="input"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Cellphone</label>
                    <div className="control">
                      <input
                        className="input"
                        value={cellphone}
                        onChange={(e) => setCellphone(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Status</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="">Please select</option>
                          <option value="pending">Pending</option>
                          <option value="active">Active</option>
                          <option value="suspended">Suspended</option>
                          <option value="removed">Removed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Client Type</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={clientType}
                          onChange={(e) => setClientType(e.target.value)}
                        >
                          <option value="">Please select</option>
                          <option value="individual">Individual</option>
                          <option value="corporate">Corporate</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Business</label>
                    <div className="control">
                      <div className="select">
                        <select
                          value={corporateInterest}
                          onChange={(e) => setCorporateInterest(e.target.value)}
                        >
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        type="submit"
                        className="button is-success is-fullwidth"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateClientForm;
