import React from "react";
import Layout from "../general/Layout";
import QuoteComp from "../../components/orders/QuoteComp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMe } from "../../features/authSlice";

const Quote = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <Layout>
      <QuoteComp></QuoteComp>
    </Layout>
  );
};

export default Quote;
