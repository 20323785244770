import React from "react";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";
import ConfirmationModal from "../general/ConfirmationModal";

const OtpList = () => {
  const [otps, setOtps] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpToDelete, setOtpToDelete] = useState(null);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getOtps = useCallback(async () => {
    try {
      const response = await axios.get("/api/otps/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOtps(response.data);
    } catch (error) {
      console.log(error.response.data);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getOtps(); // Call getUsers immediately after defining it
  }, [getOtps]);

  const deleteOtp = async (otpId) => {
    try {
      const response = await axios.delete(`api/otps/${otpId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.msg);
    } catch (error) {
      alert(error.response.data.msg);
    }
    getOtps();
  };

  const openModal = (otpId) => {
    setOtpToDelete(otpId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setOtpToDelete(null);
  };

  const confirmDelete = () => {
    if (otpToDelete !== null) {
      deleteOtp(otpToDelete);
    }
    closeModal();
  };

  return (
    <div>
      <h1 className="title">OTPs</h1>
      <h2 className="subtitle">List of OTPs</h2>

      <div className="table-container">
        {" "}
        {/* Apply responsive container */}
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Id</th>
              <th>Email</th>
              <th>OTP</th>
              <th>Validated</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {otps.map((otp, index) => (
              <tr key={otp.id}>
                <td>{index + 1}</td>
                <td>{otp.id}</td>
                <td>{otp.email}</td>
                <td>{otp.otp}</td>
                <td>{otp.validated}</td>
                <td>{otp.createdAt}</td>
                <td>
                  <button
                    onClick={() => openModal(otp.id)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        onConfirm={confirmDelete}
        onCancel={closeModal}
        message="Are you sure you want to delete this OTP?"
      />
    </div>
  );
};

export default OtpList;
