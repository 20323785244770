import React, { useEffect } from "react";
import DashboardComp from "../../components/general/DashboardComp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import Layout from "./Layout";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/clients/login"); // go back to previous page
    }
  }, [navigate, isError]);

  return (
    <Layout>
      <DashboardComp></DashboardComp>
    </Layout>
  );
};

export default Dashboard;

// notes:
// 1. // need to check first if party is true as state might be loading
// if we dont't, during loading, are going to attempt to get the state
// of the party.type and the property won't be available which will
// result in errors. This is not because it is null rather the property 'type'
// is not available. It's like getting the state of an undeclared variable
