import React from "react";
import { NavLink } from "react-router-dom";

const AboutComp = () => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="has-text-centered">
            <h1 className="title is-centered has-text-weight-bold">
              About us:
            </h1>
          </div>

          <div className="columns has-text-centered">
            <div className="column">
              <h2 className="subtitle firm-color has-text-weight-bold">
                Services
              </h2>
              <p>
                Welcome to instaLorry, your trusted partner in on-demand truck
                and bakkie logistics. We specialize in providing affordable,
                efficient, and reliable moving, delivery, and transportation
                services customised to meet your needs.
              </p>
              <br></br>

              <p>
                At instaLorry, we understand that the logistics of transporting
                goods, inventory or furniture can be challenging. We've got you
                covered, big or small load, whether you're a business in need of
                transportation that is reliable or an individual moving in
                between homes.
              </p>
              <br></br>

              {/* <ul>
                    <li>
                        <strong>Household moves:</strong> Moving furniture to your new home can be a very time
                        consuming process and requires some muscles, this should not stop you from moving to 
                        your ideal home, let us assist while you relax and drink your cup of tea. Our only expectation 
                        is that you box your items, that way the amount that we quote for the move is significantly
                        reduced. 
                    </li>
                    <li>
                        <strong>Corporate moves:</strong> We assist corporates with office relocations. Your 
                        furniture and equipment will be well looked after. Arrangements can be made for
                        short and long distances.
                    </li>
                </ul> */}

              <h2 className="subtitle firm-color has-text-weight-bold">
                Our Mission
              </h2>
              <p>
                Our mission is to simplify the logistics and transportation
                process by offering a seamless platform that connects our
                clients with a network of professional and experienced drivers.
                We aim to redefine the way goods are moved, making it a
                stress-free experience for everyone involved.
              </p>
              <br></br>

              <h2 className="subtitle firm-color has-text-weight-bold">
                Why Choose instaLorry?
              </h2>
              <ul>
                <li>
                  <strong>Reliability:</strong> We prioritize the timely and
                  secure delivery of your goods.
                </li>
                <li>
                  <strong>Affordability:</strong> Our services are competitively
                  priced to suit your budget.
                </li>
                <li>
                  <strong>Flexibility:</strong> Customize your logistics
                  solution based on your specific requirements.
                </li>
                <li>
                  <strong>Professionalism:</strong> Our drivers are skilled,
                  experienced, and committed to providing excellent service.
                </li>
              </ul>
              <br></br>

              <h2 className="subtitle firm-color has-text-weight-bold">
                Easy process
              </h2>
              <p>
                Ready to experience the instaLorry effect? Do not hesitate, get
                an instant online quote and make a booking. You won't be
                disappointed
              </p>
              <br></br>

              <NavLink
                to={"/quote"}
                className={`is-success`}
                onClick={() => {
                  // to ensure you end up at the top of the page when going to link
                  window.scroll(0, 0);
                }}
              >
                Get a free quote
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutComp;
