import { createSlice } from "@reduxjs/toolkit";

const initialVehicleState = {
  truckSize: "",
  licencePlate: "",
  shape: "", // Added shape field for vehicle type (open/closed)
  git: "",
};

const initialState = {
  name: "",
  surname: "",
  email: "",
  password: "",
  confPassword: "",
  cellphone: "",
  area: "",
  agreedToTerms: false,
  verificationCode: null,
  verifCodeSent: false,
  verifiedEmail: false,
  vehicles: [initialVehicleState],
};

const driverRegSlice = createSlice({
  name: "regDriver",
  initialState,
  reducers: {
    resetDriverReg: () => initialState,
    setName: (state, action) => {
      state.name = action.payload;
    },
    setSurname: (state, action) => {
      state.surname = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setConfPassword: (state, action) => {
      state.confPassword = action.payload;
    },
    setCellphone: (state, action) => {
      state.cellphone = action.payload;
    },
    setTruckSize: (state, action) => {
      const truckSizeValue =
        action.payload === "pleaseSelect" ? "" : action.payload;
      state.truckSize = truckSizeValue; // normally we would write this like: state.truckSize = action.payload
      // we add the truckSizeValue function because on the given options in the truckSize field, when we click 'please select' react recognises that as state and therefore state
      // therefore we add the condition so that if that is selected, the state is modified to an empty string
      // I think we could have written the value of Please Select to "" empty string
    },
    setLicencePlate: (state, action) => {
      state.licencePlate = action.payload;
    },
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setGit: (state, action) => {
      state.git = action.payload;
    },
    setShape: (state, action) => {
      state.shape = action.payload;
    },
    setAgreedToTerms: (state, action) => {
      state.agreedToTerms = action.payload;
    },
    setVerificationCode: (state, action) => {
      state.verificationCode = action.payload;
    },
    setVerifCodeSent: (state, action) => {
      state.verifCodeSent = action.payload;
    },
    setVerifiedEmail: (state, action) => {
      state.verifiedEmail = action.payload;
    },
    addVehicle: (state, action) => {
      state.vehicles.push({ ...initialVehicleState }); // Push initialVehicleState as a new vehicle
    },
    removeVehicle: (state, action) => {
      state.vehicles.splice(action.payload, 1);
    },
    updateVehicle: (state, action) => {
      const { index, data } = action.payload;
      state.vehicles[index] = { ...state.vehicles[index], ...data };
    },
    resetVehicles: (state, action) => {
      state.vehicles = [initialVehicleState];
    }, // redundant, we no longer reset individually
  },
});

export const {
  setName,
  setSurname,
  setPassword,
  setConfPassword,
  setEmail,
  setCellphone,
  setTruckSize,
  setLicencePlate,
  setArea,
  setGit,
  setShape,
  addVehicle,
  setVerificationCode,
  setVerifCodeSent,
  setVerifiedEmail,
  removeVehicle,
  updateVehicle,
  resetVehicles,
  setAgreedToTerms,
  resetDriverReg, // redundant, we no longer reset individually
} = driverRegSlice.actions;

export default driverRegSlice.reducer;
