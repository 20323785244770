import React from "react";
import ClientEmailVerForm from "../../components/clients/ClientEmailVerForm";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMe } from "../../features/authSlice";

const ClientEmailVer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <Layout>
      <ClientEmailVerForm></ClientEmailVerForm>
    </Layout>
  );
};

export default ClientEmailVer;
