import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesome's icon component
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"; // Import Facebook and Instagram icons from Font Awesome

const Footer = () => {
  const whatsappNumber = "+27658702019";

  return (
    <div>
      <footer className="footer has-border-top">
        <div className="content has-text-centered">
          <div className="columns">
            <div className="column">
              <div>Copyright Instalorry (Pty) Ltd 2024</div>
              <div>info@instalorry.co.za</div>
              <div>065 870 2019</div>
              <div>
                Bank Towers, 190 Thabo Sehume Street, Pretoria Central, Pretoria
              </div>
              <div>Website developed by Orati Software</div>
            </div>
            <div className="column">
              <div>
                <NavLink
                  to={"/terms"}
                  className="firm-color has-text-weight-bold"
                  onClick={() => {
                    // to ensure you end up at the top of the page when going to link
                    window.scroll(0, 0);
                  }}
                >
                  Terms and Conditions
                </NavLink>
              </div>
              <div>
                <NavLink
                  to={"/privacy"}
                  className="firm-color has-text-weight-bold"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  Privacy Policy
                </NavLink>
              </div>

              <div className="is-size-3 has-text-centered">
                <a
                  href="https://www.facebook.com/instalorryapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} className="firm-color" />{" "}
                  {/* Replace with the actual Facebook icon */}
                </a>
                <a
                  href="https://instagram.com/instalorry"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="firm-color" />{" "}
                  {/* Replace with the actual Instagram icon */}
                </a>
                <a
                  href="https://twitter.com/instalorry"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className="firm-color" />{" "}
                  {/* Replace with the actual Instagram icon */}
                </a>
                <a
                  href={`https://wa.me/${whatsappNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faWhatsapp} className="firm-color" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
