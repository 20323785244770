import React from "react";
import { NavLink } from "react-router-dom";

const ServicesComp = () => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="title has-text-centered">
            <h1 className="is-centered">Our Services:</h1>
          </div>

          <div className="columns has-text-centered">
            <div className="column" id="moving-section">
              <h2 className="title firm-color has-text-weight-bold">
                1. Moving
              </h2>
              <br></br>
              <h2 className="subtitle has-text-weight-bold">Introduction:</h2>

              <p>
                {" "}
                We have a team of highly skilled and trained drivers and their
                assistants, who will put so much love and care into your
                furniture and other valuable household contents.
              </p>
              <br></br>

              <h2 className="subtitle has-text-weight-bold">The process:</h2>

              <p>
                The process is very simple and straighforward. Even the less
                tech-savvy individuals can manage with the process without
                scratching their heads. Firstly, visit our website and get a
                quote. On the quote, specify the date, time, number of
                assistants to help with the move and any floors involved between
                the pickup and destination points. There is also a 'notes' field
                to specify any additional information that you might want to
                bring to the driver's attention. Once you are happy with the
                quote, proceed to confirm the details of the order and the
                payment method. Lastly, book the order.
              </p>
              <br></br>

              <p>
                Our drivers and assistants will visit your home on the scheduled
                date and time. Please have your furniture and other valuable
                contents wrapped, boxed and bubbled-wrapped. By packing and
                wrapping your furniture and contents yourself, this cuts the
                moving time and ultimately saves you costs. Most moving
                companies charge exorbitant prices due to the fact that they
                have factored the prices of packing and wrapping of furniture
                and other contents.
              </p>
              <br></br>

              <p>
                {" "}
                Watch our drivers and assistants do the rest of the work while
                you relax. We do all the hard work so that you don't have to.
                They execute the move with the utmost care and professionalism
                to minimise the risk of scratches and damages to your items. The
                drivers will proceed to offload your contents to the specified
                destination and in the desired space. Once done and you are
                happy, the drivers will complete the order on the app and you
                will receive an invoice on your registered email.
              </p>
              <br></br>

              <p>
                A post service survey email will be sent once the order is
                completed. Please remember to rate our service. This provides
                valuable feedback to help improve our service offering and
                improve on the app itself.
              </p>
              <br></br>

              <div>
                <p className="is-size-5">
                  Ready? Get a quote, click the following link...
                </p>
                <NavLink
                  to={"/quote"}
                  className={`is-success firm-color has-text-weight-bold is-size-5`}
                  onClick={() => {
                    // to ensure you end up at the top of the page when going to link
                    window.scroll(0, 0);
                  }}
                >
                  Quote
                </NavLink>
              </div>

              <br></br>
            </div>

            <div className="column" id="delivery-section">
              <h2 className="title firm-color has-text-weight-bold">
                2. Delivery
              </h2>
              <br></br>
              <h2 className="subtitle has-text-weight-bold">Introduction:</h2>

              <p>
                There are instances where you might buy an item/(s) that does
                not fit in your standard car or the shop might ask that you make
                your own delivery arrangements or they provide transport but the
                item/(s) will be delivered several days after the purchase date
                and you want it on the same day. We've got you covered.
              </p>
              <br></br>

              <h2 className="subtitle has-text-weight-bold">The process:</h2>

              <p>
                The process is very simple and straighforward. Even the less
                tech-savvy individuals can manage with the process without
                scratching their heads. Firstly, visit our website and get a
                quote. On the quote, specify the date, time, number of
                assistants to help with the delivery and any floors involved
                when the inventory has to be picked up or dropped off. There is
                also a 'notes' field to specify any additional information that
                you might want to bring to the driver's attention. Once you are
                happy with the quote, proceed to confirm the details of the
                order and the payment method. Lastly, book the order.
              </p>
              <br></br>

              <p>
                Our drivers and assistants will visit the shop on the scheduled
                date and time. Please make sure that an arrangement has been
                made with the shop to pickup the stock or that you are present
                when the driver arrives. Also, please be ready at the
                destination to accept the goods or have someone there to accept
                them.
              </p>
              <br></br>

              <p>
                Our drivers and assistants do the rest of the work while you
                relax. The stock will be loaded on the truck with the utmost
                care and professionalism to minimise the risk of scratches and
                damages to your stock. The driver will fill the relevant stock
                acceptance documents required by the shop (if any). Thereafter,
                The driver will proceed to offload your stock to the specified
                destination and in the desired space. Once done and you are
                happy, the driver will complete the order on the app and you
                will receive an invoice on your registered email.
              </p>
              <br></br>

              <p>
                A post service survey email will be sent once the order is
                completed. Please remember to rate our service. This provides
                valuable feedback to help improve our service offering and
                improve on the app itself.
              </p>
              <br></br>

              <div>
                <p className="is-size-5">
                  Ready? Get a quote, click the following link...
                </p>
                <NavLink
                  to={"/quote"}
                  className={`is-success firm-color has-text-weight-bold is-size-5`}
                  onClick={() => {
                    // to ensure you end up at the top of the page when going to link
                    window.scroll(0, 0);
                  }}
                >
                  Quote
                </NavLink>
              </div>
              <br></br>
            </div>

            <div className="column" id="partnership-section">
              <h2 className="title firm-color has-text-weight-bold">
                3. Partnership
              </h2>
              <br></br>
              <h2 className="subtitle has-text-weight-bold">Introduction:</h2>

              <p>
                There is a growing amount of corporate firms that sell different
                kinds of goods but have no trucks to transport these to the
                customer. This is understandable because as a manufacturer or
                distributor you might want to focus your value add on selling
                the product to the consumer and not on the transportation
                itself. Transport management is troublesome on its own and you
                might not want to worry yourself with it. Do not worry we can
                help. It is for this reason that we exist.
              </p>
              <br></br>

              <h2 className="subtitle has-text-weight-bold">The process:</h2>

              <p>
                The process is very simple and straighforward. Firstly, the
                business representative should go to our app and get a quote for
                the relevant delivery. On the quote, specify the date, time,
                number of assistants to help with the delivery and any floors
                involved when the inventory has to be picked-up or dropped-off.
                There is also a 'notes' field to specify any additional
                information that you might want to bring to the driver's
                attention. Once you are happy with the quote, proceed to confirm
                the details of the order and the payment method. Lastly, book
                the order. The order is booked by the business itself and not
                the client.
              </p>
              <br></br>

              <p>
                Our drivers and assistants will visit the shop on the scheduled
                date and time. Please have a representative available to assist
                our driver at the shop to fulfill the order. Arrangements should
                be made with the customer to be present at the destination to
                accept the stock or somebody appointed by the customer should be
                present for that matter.
              </p>
              <br></br>

              <p>
                Our drivers and assistants do the rest of the work. The stock
                will be loaded on the truck with the utmost care and
                professionalism to minimise the risk of scratches and damages to
                the stock. A POD (Proof of Delivery) will be signed by the
                warehouse manager once the stock has been loaded as proof that
                the goods were handed over. The driver will proceed to offload
                the stock to the specified destination and in the desired space.
                Once done and the customer is happy, the customer will sign the
                POD acknowledging the receipt of the stock the driver will
                complete the order on the app. An invoice will be sent to the
                business' registered email.
              </p>
              <br></br>

              <p>
                A post service survey email will be sent once the order is
                completed. Please remember to rate our service. This provides
                valuable feedback to help improve our service offering and
                improve on the app itself.
              </p>
              <br></br>

              <div>
                <p className="is-size-5">
                  Ready? Get a quote, click the following link...
                </p>
                <NavLink
                  to={"/quote"}
                  className={`is-success firm-color has-text-weight-bold is-size-5`}
                  onClick={() => {
                    // to ensure you end up at the top of the page when going to link
                    window.scroll(0, 0);
                  }}
                >
                  Quote
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesComp;
