import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"; // Use the correct icon package
import "../../assets/PayfastPages.css";

const ReturnComp = () => {
  return (
    // <div>
    //   {/* <FontAwesomeIcon icon={faCheckCircle} className="success-tick" /> */}
    //   <h2>Your payment was a success</h2>
    // </div>
    <div className="centered-content">
      <FontAwesomeIcon icon={faCheckCircle} className="success-tick" />
      <h2 className="title is-5">Your payment was a success</h2>
    </div>
  );
};

export default ReturnComp;
