import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";
import ConfirmationModal from "../general/ConfirmationModal";

const DriverList = () => {
  const [drivers, setDrivers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getDrivers = useCallback(async () => {
    try {
      const response = await axios.get("/api/drivers/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDrivers(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getDrivers(); // Call getUsers immediately after defining it
  }, [getDrivers]);
  // not necessary to add getUsers as a dependency
  // this is because getUsers does not depend on this dependency to execute
  // this is defined in the useCallback function

  const deleteDriver = async (driverId) => {
    try {
      const response = await axios.delete(`api/drivers/delete/${driverId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.msg);
    } catch (error) {
      alert(error.response.data.msg);
    }
    getDrivers();
  };

  const openModal = (driverId) => {
    setDriverToDelete(driverId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDriverToDelete(null);
  };

  const confirmDelete = () => {
    if (driverToDelete !== null) {
      deleteDriver(driverToDelete);
    }
    closeModal();
  };

  return (
    <div>
      <h1 className="title">Drivers</h1>
      <h2 className="subtitle">List of drivers</h2>

      <div className="table-container">
        {" "}
        {/* Apply responsive container */}
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Id</th>
              <th>Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Cellphone</th>
              <th>Area</th>
              <th>Owner</th>
              <th>Vehicle Id</th>
              <th>Owner Id</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, index) => (
              <tr key={driver.id}>
                <td>{index + 1}</td>
                <td>{driver.id}</td>
                <td>{driver.name}</td>
                <td>{driver.surname}</td>
                <td>{driver.email}</td>
                <td>{driver.cellphone}</td>
                <td>{driver.area}</td>
                <td>{driver.ownerDriver}</td>
                <td>{driver.vehicleId}</td>
                <td>{driver.ownerId}</td>
                <td>{driver.status}</td>
                <td>
                  <Link
                    to={`/drivers/update/${driver.id}`}
                    className="button is-small is-info"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => openModal(driver.id)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        onConfirm={confirmDelete}
        onCancel={closeModal}
        message="Are you sure you want to delete this driver?"
      />
    </div>
  );
};

export default DriverList;
