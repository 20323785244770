// src/components/FloatingWhatsApp.js
import React from "react";
import "../../assets/FloatingWhatsApp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesome's icon component
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"; // Import Facebook and Instagram icons from Font Awesome

const FloatingWhatsApp = () => {
  const whatsappNumber = "+27658702019";

  return (
    <a
      href={`https://wa.me/${whatsappNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="floating-whatsapp"
    >
      <FontAwesomeIcon icon={faWhatsapp} size="3x" className="whatsapp-icon" />
    </a>
  );
};

export default FloatingWhatsApp;
