import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logoOfficial from "../../assets/logoOfficial.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/authSlice";
import Sidebar from "./Sidebar";
import { useMediaQuery } from "react-responsive";
import { BsTelephoneOutboundFill } from "react-icons/bs";

const Navbar = () => {
  const [burgerClass, setBurgerClass] = useState(false);

  const navigate = useNavigate();

  const { party } = useSelector((store) => store.auth);
  const isTouchDevice = useMediaQuery({ maxWidth: 1023 }); // max length for a touch devices

  // Toggle burger menu visibility
  const toggleBurgerMenu = () => {
    setBurgerClass(!burgerClass);
  };

  // logout logic
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    dispatch(reset());
    navigate("/");
    alert("Logout successful");
  };

  return (
    <nav
      className="navbar is-fixed-top has-border-bottom"
      role="navigation"
      aria-label="main navigation"
    >
      {" "}
      {/* Parent container with the 'navbar' class */}
      <div className="navbar-brand">
        <NavLink className="navbar-item">
          {/* Logo Navbar */}
          <img
            src={logoOfficial}
            alt="company_brand"
            style={{ maxHeight: "50px" }}
          />
        </NavLink>
        <NavLink
          role="button"
          onClick={toggleBurgerMenu}
          className={`navbar-burger ${burgerClass ? "is-active" : ""}`}
          id="burger"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </NavLink>
      </div>
      {/* Navigation Links Navbar */}
      {/**navbar */}
      <div
        className={`navbar-menu is-centered ${burgerClass ? "is-active" : ""}`}
        id="nav-links"
      >
        <div className="navbar-start">
          <div className="navbar-item telephone">
            <BsTelephoneOutboundFill></BsTelephoneOutboundFill>
            <p className="ml-2">(065) 870 2019</p>
          </div>
        </div>

        <div className="navbar-end is-multiline-mobile">
          <NavLink
            to={"/"}
            className="navbar-item navbar-button has-text-weight-bold"
            onClick={() => {
              // to ensure you end up at the top of the page when going to link
              window.scroll(0, 0);
            }}
          >
            Home
          </NavLink>

          {!party && (
            <div
              className={`navbar-item has-dropdown is-centered is-hoverable`}
            >
              <NavLink className="navbar-link firm-color has-text-weight-bold">
                Login
              </NavLink>
              <div className="navbar-dropdown">
                <NavLink
                  to={"/clients/login"}
                  className="navbar-item navbar-button firm-color has-text-weight-bold"
                  onClick={() => {
                    // to ensure you end up at the top of the page when going to link
                    window.scroll(0, 0);
                  }}
                >
                  Client login
                </NavLink>
                <NavLink
                  to={"/users/login"}
                  className="navbar-item navbar-button firm-color has-text-weight-bold"
                  onClick={() => {
                    // to ensure you end up at the top of the page when going to link
                    window.scroll(0, 0);
                  }}
                >
                  Admin login
                </NavLink>
              </div>
            </div>
          )}

          <NavLink
            to={"/quote"}
            className="navbar-item navbar-button firm-color has-text-weight-bold"
            onClick={() => {
              // to ensure you end up at the top of the page when going to link
              window.scroll(0, 0);
            }}
          >
            Get a Quote
          </NavLink>

          <NavLink
            to={"/drivers/register"}
            className="navbar-item navbar-button firm-color has-text-weight-bold"
            onClick={() => {
              // to ensure you end up at the top of the page when going to link
              window.scroll(0, 0);
            }}
          >
            Become a Driver
          </NavLink>

          <NavLink
            to={"/clients/register"}
            className="navbar-item navbar-button firm-color has-text-weight-bold"
            onClick={() => {
              // to ensure you end up at the top of the page when going to link
              window.scroll(0, 0);
            }}
          >
            Client Registration
          </NavLink>

          <NavLink
            to={"/contact"}
            className="navbar-item navbar-button firm-color has-text-weight-bold"
            onClick={() => {
              // to ensure you end up at the top of the page when going to link
              window.scroll(0, 0);
            }}
          >
            Contact Us
          </NavLink>

          <NavLink
            to={"/about"}
            className="navbar-item navbar-button firm-color has-text-weight-bold"
            onClick={() => {
              // to ensure you end up at the top of the page when going to link
              window.scroll(0, 0);
            }}
          >
            About Us
          </NavLink>

          {party && (
            // <div className="navbar-item navbar-button firm-color has-text-weight-bold">
            //   <div className="buttons">
            //     <NavLink className="button" onClick={handleLogout}>
            //       Log out
            //     </NavLink>
            //   </div>
            // </div>
            <NavLink
              to={"/about"}
              className="navbar-item navbar-button firm-color has-text-weight-bold"
              onClick={handleLogout}
            >
              Logout
            </NavLink>
          )}
          {/* {(isMobile || burgerClass) && (
            <SidebarNew burgerClass={burgerClass} />
          )} */}
          {isTouchDevice && <Sidebar />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
