import React from "react";
import miniVan from "../../assets/miniVan.jpg";
import oneTon from "../../assets/1ton.png";
import oneFiveTon from "../../assets/1.5ton.jpg";
import threeTon from "../../assets/3ton.png";
import eightTon from "../../assets/8ton.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTruckSize } from "../../features/orderSlice";

const TruckSizesInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { truckSize, floors } = useSelector((store) => store.order);

  const selectTruckSize = (size, e) => {
    e.preventDefault();
    dispatch(setTruckSize(size));
    navigate(-1); // Go back to the previous page
    console.log("Button clicked:");
  };

  console.log("the truck size is:", truckSize);
  console.log("the floors is:", floors);

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="title has-text-centered">
            <h1 className="is-centered">Our trucks:</h1>
          </div>

          <div className="columns has-text-centered">
            <div className="column">
              <div className="card">
                <p className="card-header-title is-centered is-size-3 firm-color">
                  mini van
                </p>
                <div className="card-image">
                  <figure className="image is-3by2">
                    <img src={miniVan} alt="moving"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    Nissan NP200 or similar. This is suitable for small
                    appliances or furniture. You can fit a fridge, washing
                    machine, coffee table and some few boxes.
                  </p>
                  {/* <NavLink
                    // to="/"
                    className="button is-primary"
                    onClick={() => selectTruckSize("0.5ton")}
                  >
                    Select
                  </NavLink> */}
                  <button
                    className="button blue-background-color has-text-white is-rounded"
                    onClick={(e) => selectTruckSize("0.5ton", e)}
                  >
                    Select mini van
                  </button>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card">
                <p className="card-header-title is-centered is-size-3 firm-color">
                  1 ton bakkie
                </p>
                <div className="card-image">
                  <figure className="image is-3by2">
                    <img src={oneTon} alt="delivery"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    Toyota Hilux, Nissan NP300, Mitsubishi Colt or similar. This
                    is suitable for small moves. You can fit a queen bed, medium
                    sized fridge, washing machine, coffee table and some few
                    boxes.
                  </p>
                  <button
                    className="button blue-background-color has-text-white is-rounded"
                    onClick={(e) => selectTruckSize("1ton", e)}
                  >
                    Select 1 ton
                  </button>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card">
                <p className="card-header-title is-centered is-size-3 firm-color">
                  1.5 ton bakkie
                </p>
                <div className="card-image">
                  <figure className="image is-3by2">
                    <img src={oneFiveTon} alt="partnership"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    Hyundai H100, Kia K2700, Kia K2500 or similar. This is
                    suitable for small to medium sized moves. You can fit a
                    queen bed, medium sized fridge, washing machine, 2 seater
                    couch, tv stand, coffee table and some few boxes
                  </p>
                  <button
                    className="button blue-background-color has-text-white is-rounded"
                    onClick={(e) => selectTruckSize("1.5ton", e)}
                  >
                    Select 1.5 ton
                  </button>
                </div>
              </div>
            </div>

            <div className="column" id="truckSizeField">
              <div className="card">
                <p className="card-header-title is-centered is-size-3 firm-color">
                  3 ton bakkie
                </p>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={threeTon} alt="partnership"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    This is suitable for medium sized moves. You can fit the
                    content of a 2 bedroom flat.
                  </p>
                  <button
                    className="button blue-background-color has-text-white is-rounded"
                    onClick={(e) => selectTruckSize("3ton", e)}
                  >
                    Select 3 ton
                  </button>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card">
                <p className="card-header-title is-centered is-size-3 firm-color">
                  8 ton bakkie
                </p>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={eightTon} alt="partnership"></img>
                  </figure>
                </div>
                <div className="card-content">
                  <p>
                    This is suitable for large sized moves. You can fit the
                    content of a 3 bedroom house.
                  </p>
                  <button
                    className="button blue-background-color has-text-white is-rounded"
                    onClick={(e) => selectTruckSize("8ton", e)}
                  >
                    Select 8 ton
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <NavLink
              to={-1}
              className={`is-success button`}
              onClick={() => {
                // to ensure you end up at the top of the page when going to link
                //   window.scroll(0, 0);
              }}
            >
              Click here to continue with quote
            </NavLink>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default TruckSizesInfo;
