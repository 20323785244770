import React from "react";
import UpdateClientPassForm from "../../components/clients/UpdateClientPassForm";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const UpdateClientPass = () => {
  const dispatch = useDispatch();
  const { verifiedEmail, email } = useSelector((state) => state.regClient);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <>
      {verifiedEmail && email ? (
        <Layout>
          <UpdateClientPassForm></UpdateClientPassForm>
        </Layout>
      ) : (
        <Navigate to="/clients/forgotpassword" />
      )}
      {/* <Layout>
        <AddClientForm></AddClientForm>
      </Layout> */}
    </>
  );
};

export default UpdateClientPass;
