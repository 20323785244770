import React from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import InfoIconModal from "../general/InfoIconModal";
// import BusinessIconModal from "../general/BusinessIconModal";
import {
  setName,
  setSurname,
  setPassword,
  setConfPassword,
  setCellphone,
  setAgreedToTerms,
  setCorporateInterest,
} from "../../features/clientRegSlice";
// import { getToken } from "../../features/tokenSlice";

const AddClientForm = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  const navigate = useNavigate();

  const {
    name,
    surname,
    email,
    password,
    confPassword,
    cellphone,
    agreedToTerms,
    corporateInterest,
  } = useSelector((state) => state.regClient);

  // Password validation function
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 10) {
      errors.push("Password must be at least 10 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    return errors;
  };

  // frontend fields validation for completeness
  const validateFields = () => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!surname) {
      errors.surname = "Surname is required";
    }
    if (!password) {
      errors.password = "Password is required";
    } else {
      const passwordErrors = validatePassword(password);
      if (passwordErrors.length > 0) {
        errors.password = passwordErrors.join(" ");
      }
    }
    if (!confPassword) {
      errors.confPassword = "Confirm Password is required";
    } else if (confPassword !== password) {
      errors.confPassword = "Passwords do not match";
    }
    if (!cellphone) {
      errors.cellphone = "Cellphone is required";
    }
    if (!agreedToTerms) {
      errors.agreedToTerms = "Please agree to the Terms and Conditions";
    }

    if (
      !name ||
      !surname ||
      !password ||
      !confPassword ||
      !cellphone ||
      !agreedToTerms
    ) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // this returns the keys of the errors object
  // remember each object has a key and a value
  // keys are name, cellphone, etc. If there are errors it returns false

  const saveClient = async (e) => {
    e.preventDefault();
    // letting this stay here causes errors
    setIsLoading(true);
    // validate the completeness of the fields
    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post("/api/clients/register", {
        name: name, // this is the data that we want to post
        // for name we attach the name from the state
        surname: surname,
        email: email,
        password: password,
        confPassword: confPassword,
        cellphone: cellphone,
        corporateInterest: corporateInterest,
      });
      // setMsg(response.data.msg);
      alert(response.data.msg);
      // Use a callback to ensure clearForm is called after navigation completes
      navigate("/clients/login");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
        // alert(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const clearForm = () => {
  //   dispatch(setName(""));
  //   dispatch(setSurname(""));
  //   dispatch(setEmail(""));
  //   dispatch(setPassword(""));
  //   dispatch(setConfPassword(""));
  //   dispatch(setCellphone(""));
  //   dispatch(setAgreedToTerms(false));
  //   dispatch(setCorporateInterest("no"));
  //   dispatch(setVerificationCode(null));
  //   dispatch(setVerifCodeSent(false));
  //   dispatch(setVerifiedEmail(false));
  //   // this is because it causes AddClientForm to navigate to "/clients/register" to to conditional in AddClient
  //   // causing redirect issues when navigate to "/clients/login" should occur after submitting form data
  // };

  return (
    <div>
      <section className="section">
        <div className="column">
          <div>
            <h1 className="title has-text-centered has-text-weight-bold">
              Customers
            </h1>
            <div className="columns is-centered">
              <div className="column is-8">
                <div className="card">
                  <p className="card-header-title is-centered blue-font-color is-size-4">
                    Please fill in the rest of your details
                  </p>
                  <div className="card-content">
                    <div className="content">
                      <form>
                        <div className="field">
                          <label className="label blue-font-color">Name</label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.name ? "is-danger" : ""
                              }`}
                              value={name}
                              onChange={(e) =>
                                dispatch(setName(e.target.value))
                              }
                            ></input>
                          </div>
                          {errors.name && (
                            <p className="help is-danger">{errors.name}</p>
                          )}
                        </div>

                        <div className="field">
                          <label className="label blue-font-color">
                            Surname
                          </label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.surname ? "is-danger" : ""
                              }`}
                              value={surname}
                              onChange={(e) =>
                                dispatch(setSurname(e.target.value))
                              }
                            ></input>
                          </div>
                          {errors.surname && (
                            <p className="help is-danger">{errors.surname}</p>
                          )}
                        </div>

                        <div className="field">
                          <label className="label blue-font-color">
                            Password
                          </label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.password ? "is-danger" : ""
                              }`}
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) =>
                                dispatch(setPassword(e.target.value))
                              }
                            ></input>
                            {/* <InfoIconModal></InfoIconModal> */}
                            <button
                              type="button"
                              className="button is-green is-small is-primary mt-1"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "👁️" : "👁️‍🗨️"}
                            </button>
                          </div>
                          {errors.password && (
                            <p className="help is-danger">{errors.password}</p>
                          )}
                        </div>

                        <div className="field">
                          <label className="label blue-font-color">
                            Confirm Password
                          </label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.confPassword ? "is-danger" : ""
                              }`}
                              type={showPassword ? "text" : "password"}
                              value={confPassword}
                              onChange={(e) =>
                                dispatch(setConfPassword(e.target.value))
                              }
                            ></input>
                            <button
                              type="button"
                              className="button is-green is-small is-primary mt-1"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? "👁️" : "👁️‍🗨️"}
                            </button>
                          </div>
                          {errors.confPassword && (
                            <p className="help is-danger">
                              {errors.confPassword}
                            </p>
                          )}
                        </div>

                        <div className="field">
                          <label className="label blue-font-color">
                            Cellphone
                          </label>
                          <div className="control">
                            <input
                              className={`input ${
                                errors.cellphone ? "is-danger" : ""
                              }`}
                              value={cellphone}
                              onChange={(e) =>
                                dispatch(setCellphone(e.target.value))
                              }
                            ></input>
                          </div>
                          {errors.cellphone && (
                            <p className="help is-danger">{errors.cellphone}</p>
                          )}
                        </div>

                        <div className="field">
                          <label className="label blue-font-color">
                            Once-Off or Business (business is for future
                            recurring orders)
                          </label>
                          <div className="field">
                            <div className="control">
                              <div className="select">
                                <select
                                  value={corporateInterest}
                                  onChange={(e) =>
                                    dispatch(
                                      setCorporateInterest(e.target.value)
                                    )
                                  }
                                >
                                  <option value="no">Once-Off</option>
                                  <option value="yes">Business Client</option>
                                </select>
                              </div>
                              {/* <BusinessIconModal></BusinessIconModal> */}
                            </div>
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <label className="checkbox">
                              <input
                                type={`checkbox`}
                                checked={agreedToTerms}
                                onChange={() =>
                                  dispatch(setAgreedToTerms(!agreedToTerms))
                                }
                              />{" "}
                              {/**add space*/}I agree to the
                              <NavLink
                                to="/terms"
                                onClick={() => {
                                  // to ensure you end up at the top of the page when going to link
                                  window.scroll(0, 0);
                                }}
                              >
                                {" "}
                                Terms and Conditions
                              </NavLink>
                            </label>
                          </div>
                          {errors.agreedToTerms && (
                            <p className="help is-danger">
                              {errors.agreedToTerms}
                            </p>
                          )}
                        </div>

                        <p className="has-text-centered has-text-weight-bold">
                          {msg}
                        </p>

                        {/**frontend completeness validation oeverall message */}
                        {overallError && (
                          <p className="has-text-centered has-text-danger">
                            {overallError}
                          </p>
                        )}

                        <div className="field">
                          <div className="control">
                            <button
                              disabled={isLoading}
                              onClick={saveClient}
                              className="button blue-background-color has-text-white is-fullwidth"
                            >
                              {isLoading ? "Loading..." : "Submit"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddClientForm;
