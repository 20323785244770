import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  setTruckSize,
  setLicencePlate,
  setShape,
  setGit,
  setAgreedToTerms,
} from "../../features/vehicleOnlyRegSlice";

// ** Redundant for now

const AddVehicleOnlyForm = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  //   const navigate = useNavigate();

  const { truckSize, licencePlate, shape, git, agreedToTerms } = useSelector(
    (state) => state.regVehicleOnly
  );

  // frontend fields validation for completeness
  const validateFields = () => {
    const errors = {};
    if (!truckSize) {
      errors.truckSize = "Truck size is required";
    }
    if (!licencePlate) {
      errors.licencePlate = "Licence plate is required";
    }
    if (!shape) {
      errors.shape = "Vehicle shape is required";
    }
    if (!git) {
      errors.git = "Goods in Transit (GIT) info is required";
    }
    if (!agreedToTerms) {
      errors.agreedToTerms = "Please agree to the Terms and Conditions";
    }

    if (!truckSize || !licencePlate || !shape || !git || !agreedToTerms) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  const saveVehicle = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // validate the completeness of the fields
    const isValid = validateFields();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post("/api/vehicles/register/vehicleonly", {
        truckSize: truckSize, // this is the data that we want to post
        // for name we attach the name from the state
        licencePlate: licencePlate,
        shape: shape,
        git: git,
      });
      setMsg(response.data.msg);
      //   navigate("/clients/login");
      //   alert(response.data.msg);

      //   navigate("/clients/verify") // after receiving client verification email
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
        // alert(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>
        <h1 className="title has-text-centered">Vehicles</h1>
        <h1 className="subtitle has-text-centered">Register a vehicle</h1>
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="card is-shadowless">
              <div className="card-content">
                <div className="content">
                  <form>
                    <div className="field">
                      <label className="label">Truck Size</label>
                      <div className="control">
                        <div
                          className={`select ${
                            errors.truckSize ? "is-danger" : ""
                          }`}
                        >
                          <select
                            value={truckSize}
                            onChange={(e) =>
                              dispatch(setTruckSize(e.target.value))
                            }
                          >
                            <option value="">Please select</option>
                            <option value="0.5ton">half ton</option>
                            <option value="1ton">1 ton</option>
                            <option value="1.5ton">1.5 ton</option>
                            <option value="3ton">3 ton</option>
                            <option value="8ton">8 ton</option>
                          </select>
                        </div>
                      </div>
                      {errors.truckSize && (
                        <p className="help is-danger">{errors.truckSize}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Licence Plate</label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.licencePlate ? "is-danger" : ""
                          }`}
                          value={licencePlate}
                          onChange={(e) =>
                            dispatch(setLicencePlate(e.target.value))
                          }
                        ></input>
                      </div>
                      {errors.licencePlate && (
                        <p className="help is-danger">{errors.licencePlate}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">Body shape</label>
                      <div className="control">
                        <div
                          className={`select ${
                            errors.shape ? "is-danger" : ""
                          }`}
                        >
                          <select
                            value={shape}
                            onChange={(e) => dispatch(setShape(e.target.value))}
                          >
                            <option value="">Please select</option>
                            <option value="open">Open</option>
                            <option value="closed">Closed</option>
                          </select>
                        </div>
                      </div>
                      {errors.shape && (
                        <p className="help is-danger">{errors.shape}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label">
                        Do you have GIT - Goods In Transit Insurance
                      </label>
                      <div className="control">
                        <div
                          className={`select ${errors.git ? "is-danger" : ""}`}
                        >
                          <select
                            value={git}
                            onChange={(e) => dispatch(setGit(e.target.value))}
                          >
                            <option value="">Please select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                      {errors.git && (
                        <p className="help is-danger">{errors.git}</p>
                      )}
                    </div>

                    <div className="field">
                      <div className="control">
                        <label className="checkbox">
                          <input
                            type={`checkbox`}
                            checked={agreedToTerms}
                            onChange={() =>
                              dispatch(setAgreedToTerms(!agreedToTerms))
                            }
                          />{" "}
                          {/**add space*/}I agree to the
                          <NavLink
                            to="/terms"
                            onClick={() => {
                              // to ensure you end up at the top of the page when going to link
                              window.scroll(0, 0);
                            }}
                          >
                            {" "}
                            Terms and Conditions
                          </NavLink>
                        </label>
                      </div>
                      {errors.agreedToTerms && (
                        <p className="help is-danger">{errors.agreedToTerms}</p>
                      )}
                    </div>

                    <p className="has-text-centered has-text-weight-bold">
                      {msg}
                    </p>

                    {/**frontend completeness validation oeverall message */}
                    {overallError && (
                      <p className="has-text-centered has-text-danger">
                        {overallError}
                      </p>
                    )}

                    <div className="field">
                      <div className="control">
                        <button
                          onClick={saveVehicle}
                          className="button is-success is-fullwidth"
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVehicleOnlyForm;
