import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../features/tokenSlice";

const EditPricingForm = () => {
  const [truckSizePricing, setTruckSizePricing] = useState(""); // changing the name bacause it is already used in params
  const [baseAmount, setBaseAmount] = useState("");
  const [ratePerKm, setRatePerKm] = useState("");
  const [helpersPricing, setHelpersPricing] = useState("");
  const [floorsPricing, setFloorsPricing] = useState("");
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const navigate = useNavigate();
  const { truckSize } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getPriceById = useCallback(async () => {
    try {
      const response = await axios.get(`/api/pricing/${truckSize}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTruckSizePricing(response.data.truckSize);
      setBaseAmount(response.data.baseAmount);
      setRatePerKm(response.data.ratePerKm);
      setHelpersPricing(response.data.helpersPricing);
      setFloorsPricing(response.data.floorsPricing);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  }, [token, truckSize]);

  useEffect(() => {
    getPriceById();
  }, [getPriceById]);

  const updatePrice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.patch(
        `/api/pricing/${truckSize}`,
        {
          truckSize: truckSizePricing, // same as: truckSize: truckSize
          baseAmount,
          ratePerKm,
          helpersPricing,
          floorsPricing,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/pricing");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>
        <h1 className="title">Prices</h1>
        <h2 className="subtitle">Update Vehicle Pricing</h2>
        <div className="columns iscentered">
          <div className="column is-8">
            <div className="card-content">
              <div className="content">
                <form onSubmit={updatePrice}>
                  <p>{msg}</p>

                  <div className="field">
                    <label className="label">Truck Size</label>
                    <div className="control">
                      <input
                        className="input"
                        value={truckSizePricing}
                        onChange={(e) => setTruckSizePricing(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Base Amount</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={baseAmount}
                        onChange={(e) => setBaseAmount(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Rate Per Km</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        value={ratePerKm}
                        onChange={(e) => setRatePerKm(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Price Per Helper</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        value={helpersPricing}
                        onChange={(e) => setHelpersPricing(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Price Per Floor</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        value={floorsPricing}
                        onChange={(e) => setFloorsPricing(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="button is-success is-fullwidth"
                      >
                        {isLoading ? "Loading..." : "Update"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPricingForm;
