import React from "react";
import UserList from "../../components/users/UserList";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, party } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/clients/login");
    } else if (
      party &&
      (party.type !== "user" ||
        (party.type === "user" && party.role !== "admin"))
    ) {
      // note (1)
      navigate("/dashboard");
    }
  }, [party, navigate, isError]);

  return (
    <Layout>
      <UserList></UserList>
    </Layout>
  );
};

export default Users;
