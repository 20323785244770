import React from "react";
import Layout from "../general/Layout";
import OrderTermsComp from "../../components/orders/OrderTermsComp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const OrderTerms = () => {
  const dispatch = useDispatch();

  const { price } = useSelector((store) => store.order);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <>
      {price ? (
        <Layout>
          <OrderTermsComp></OrderTermsComp>
        </Layout>
      ) : (
        <Navigate to="/quote" />
      )}
    </>
  );
};

export default OrderTerms;
