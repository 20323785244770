import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  setEmail,
  setVerificationCode,
  setVerifCodeSent,
  setVerifiedEmail,
} from "../../features/driverRegSlice";

const DriverEmailVerForm = () => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(""); // we will display this message if there an error
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState({});
  const [verifCodeError, setVerifCodeError] = useState({});
  const [resendOtpEnabled, setResendOtpEnabled] = useState(false); // To manage the resend OTP button state
  const [timer, setTimer] = useState(0); // Timer state to display countdown

  const navigate = useNavigate();

  const { email, verificationCode, verifCodeSent } = useSelector(
    (state) => state.regDriver
  );

  // frontend email validation for completeness
  const validateEmail = () => {
    const emailError = {};

    if (!email) {
      emailError.email = "Email is required";
    }

    setEmailError(emailError);

    return Object.keys(emailError).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  // frontend email validation for completeness
  const validateVerifCode = () => {
    const verifCodeError = {};

    if (!verificationCode) {
      verifCodeError.verificationCode = "Verification code is required";
    }

    setVerifCodeError(verifCodeError);

    return Object.keys(verifCodeError).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  const sendOtp = async (e) => {
    e && e.preventDefault();
    // letting this stay here causes errors
    setIsLoading(true);
    // validate the completeness of the fields
    const isValid = validateEmail();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post("/api/drivers/createOtp", {
        email: email, // this is the data that we want to post
        // for name we attach the name from the state
      });
      if (
        response.data.verifCodeSent &&
        response.data.verifCodeSent === "yes"
      ) {
        dispatch(setVerifCodeSent(true));
        setResendOtpEnabled(false); // Disable the resend OTP button
        setTimer(30); // Set the timer to 30 seconds
      }

      // if (
      //   response.data.verifiedEmail &&
      //   response.data.verifiedEmail === "yes"
      // ) {
      //   dispatch(setVerifiedEmail(true));
      //   navigate("/drivers/register/form");
      // }
      setMsg(response.data.msg);
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resendOtp = async (e) => {
    e && e.preventDefault(); // Prevent form submission if e is defined (not called from resend button)
    // setup this way due to calling this function again via resend otp button
    // letting this stay here causes errors
    setIsLoading(true);
    // validate the completeness of the fields
    const isValid = validateEmail();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post("/api/drivers/createOtp", {
        email: email,
      });

      if (
        response.data.verifCodeSent &&
        response.data.verifCodeSent === "yes"
      ) {
        dispatch(setVerifCodeSent(true));
        setResendOtpEnabled(false); // Disable the resend OTP button
        setTimer(30); // Set the timer to 30 seconds
      }

      // if (
      //   response.data.verifiedEmail &&
      //   response.data.verifiedEmail === "yes"
      // ) {
      //   dispatch(setVerifiedEmail(true));
      //   navigate("/clients/register/form");
      // }
      setMsg("An OTP has been re-sent, please check your email");

      //   navigate("/clients/verify") // after receiving client verification email
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg); // if there is an error we will set it in the state
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // validate the completeness of the fields
    const isValid = validateVerifCode();
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post("/api/drivers/verifyOtp", {
        email: email,
        verificationCode: verificationCode,
      });

      if (
        response.data.verifiedEmail &&
        response.data.verifiedEmail === "yes"
      ) {
        dispatch(setVerifiedEmail(true));
        navigate("/drivers/register/form");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const changeEmail = () => {
    navigate("/drivers/register");
  };

  // Handle the countdown timer for the resend OTP button
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendOtpEnabled(true); // Re-enable the resend OTP button
    }
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div>
      <section className="section">
        <div className="container">
          <div>
            <h1 className="title has-text-centered has-text-weight-bold">
              Drivers
            </h1>
            <div className="columns is-centered">
              <div className="column is-8">
                <div className="card">
                  <p className="card-header-title is-centered blue-font-color is-size-4">
                    Become a driver
                  </p>
                  <div className="card-content">
                    <div className="content">
                      <p className="card-header-title is-centered is-size-6">
                        Do you own a truck or bakkie? Well we have the clients!
                        Together we can grow and make money.
                      </p>
                      <p className="card-header-title is-centered is-size-6 has-text-danger">
                        Please note that we are currently accepting drivers only
                        from Gauteng. We will expand to other provinces if there
                        is sufficient demand.
                      </p>
                      <p className="card-header-title is-centered is-size-6">
                        Your account will be placed on 'pending' once you
                        register. When you send the required information and
                        management reviews them, your status will then change to
                        'active'.
                      </p>
                      <div className="card-content is-centered is-size-6">
                        <ol>
                          {" "}
                          The required documents are:
                          <li>Driver's licence</li>
                          <li>Vehicle licence disc</li>
                          <li>Driver passport or id sized photo</li>
                          <li>
                            Proof of GIT (if any - serves as an advantage)
                          </li>
                          <li>
                            Front photo of the vehicle (show licence plate)
                          </li>
                          <li>
                            Back photo of the vehicle (show licence plate)
                          </li>
                          <li>Right side photo of the vehicle</li>
                          <li>Left side photo of the vehicle</li>
                          Send the documents to the Whatsapp number: 065 870
                          2019
                        </ol>
                      </div>
                      <br></br>
                      <form>
                        {!verifCodeSent && (
                          <div className="field">
                            <label className="label blue-font-color">
                              Please enter your email
                            </label>
                            <div className="control">
                              <input
                                className={`input ${
                                  emailError.email ? "is-danger" : ""
                                }`}
                                type="email"
                                value={email}
                                onChange={(e) =>
                                  dispatch(setEmail(e.target.value))
                                }
                              ></input>
                            </div>
                            {emailError.email && (
                              <p className="help is-danger">
                                {emailError.email}
                              </p>
                            )}
                          </div>
                        )}

                        {verifCodeSent && email && (
                          <div className="field">
                            <label className="label blue-font-color">
                              Enter verification code
                            </label>
                            <div className="control">
                              <input
                                className={`input ${
                                  verifCodeError.verificationCode
                                    ? "is-danger"
                                    : ""
                                }`}
                                value={verificationCode}
                                onChange={(e) =>
                                  dispatch(setVerificationCode(e.target.value))
                                }
                              ></input>
                            </div>
                            {verifCodeError.verificationCode && (
                              <p className="help is-danger">
                                {verifCodeError.verificationCode}
                              </p>
                            )}
                          </div>
                        )}

                        <p className="has-text-centered has-text-weight-bold">
                          {msg}
                        </p>
                        {verifCodeSent && (
                          <p className="has-text-centered">{email}</p>
                        )}

                        {!verifCodeSent && (
                          <div className="field">
                            <div className="control">
                              <button
                                disabled={isLoading}
                                onClick={sendOtp}
                                className="button blue-background-color has-text-white is-rounded"
                              >
                                {isLoading ? "Loading..." : "Submit"}
                              </button>
                            </div>
                          </div>
                        )}

                        {verifCodeSent && email && (
                          <div className="field">
                            <div className="control">
                              <button
                                disabled={isLoading}
                                onClick={verifyOtp}
                                className="button blue-background-color has-text-white is-rounded"
                              >
                                {isLoading ? "Loading..." : "Submit OTP"}
                              </button>
                            </div>
                          </div>
                        )}
                        <br></br>

                        {verifCodeSent && email && (
                          <div className="field">
                            <div className="control">
                              <button
                                onClick={changeEmail}
                                className="button blue-background-color has-text-white is-rounded"
                              >
                                Change email
                              </button>
                            </div>
                          </div>
                        )}
                        <br></br>

                        {verifCodeSent && email && resendOtpEnabled && (
                          <div className="field">
                            <div className="control">
                              <button
                                // disabled={!resendOtpEnabled || isLoading}
                                disabled={isLoading}
                                onClick={resendOtp}
                                className="button blue-background-color has-text-white is-rounded"
                              >
                                {isLoading ? "Loading..." : "Resend OTP"}
                              </button>
                            </div>
                          </div>
                        )}

                        {timer > 0 && (
                          <p className="has-text-centered">
                            You can resend OTP in {timer} seconds
                          </p>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DriverEmailVerForm;
