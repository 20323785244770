import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Login, reset } from "../../features/authSlice";

const LoginFormUser = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { party, isError, isSuccess, isLoading, message } = useSelector(
    (store) => store.auth
  );

  useEffect(() => {
    if (party || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [party, isSuccess, navigate, dispatch]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(Login({ email, password, loginPartyType: "user" }));
  };

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8">
              <form onSubmit={Auth} className="box">
                {isError && <p className="has-text-centered">{message}</p>}

                <h1 className="title is-2 has-text-centered has-text-weight-bold">
                  Admin Sign In
                </h1>

                <div className="field">
                  <label className="label blue-font-color">Email</label>
                  <div className="cntrol">
                    <input
                      type="email"
                      className="input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label blue-font-color">Password</label>
                  <div className="control">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="button is-green is-small is-primary mt-1"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "👁️" : "👁️‍🗨️"}
                  </button>
                </div>

                <div className="field mt-5">
                  <button
                    type="submit"
                    className="button blue-background-color has-text-white is-fullwidth"
                  >
                    {isLoading ? "Loading..." : "Login"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginFormUser;
