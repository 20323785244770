import React from "react";
import AddClientForm from "../../components/clients/AddClientForm";
import Layout from "../general/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const AddClient = () => {
  const dispatch = useDispatch();
  const { verifiedEmail, email } = useSelector((state) => state.regClient);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <>
      {verifiedEmail && email ? (
        <Layout>
          <AddClientForm />
        </Layout>
      ) : (
        <Navigate to="/clients/register" />
      )}
      {/* <Layout>
        <AddClientForm></AddClientForm>
      </Layout> */}
    </>
  );
};

export default AddClient;
