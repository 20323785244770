import React from "react";
import Layout from "../general/Layout";
import LoginFormClient from "../../components/clients/LoginFormClient";

const LoginClient = () => {
  return (
    <Layout>
      <LoginFormClient></LoginFormClient>
    </Layout>
  );
};

export default LoginClient;
