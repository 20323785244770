import React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setTruckSize,
  setOrderDate,
  setOrderTime,
  setPickup,
  setDestination,
  setPickupPlace,
  setDestinationPlace,
  setKms,
  setFloors,
  setHelpers,
  setBaseCost,
  setDistanceCost,
  setHelpersCost,
  setFloorsCost,
  setMidmonthDiscount,
  setRepeatDiscount,
  setPromotionDiscount,
  setPrice,
  setCommission,
  setNet,
  calculateQuote,
} from "../../features/orderSlice";

const library = ["places"];

const QuoteComp = () => {
  const dispatch = useDispatch();
  const [pricing, setPricing] = useState(null);
  const [errors, setErrors] = useState({});
  const [overallError, setOverallError] = useState(""); // Single overall error message

  const navigate = useNavigate();

  const {
    truckSize,
    orderDate,
    orderTime,
    pickup,
    destination,
    pickupPlace,
    destinationPlace,
    kms,
    floors,
    helpers,
    // baseCost,
    // distanceCost,
    // floorsCost,
    // helpersCost,
    // midmonthDiscount,
    // repeatDiscount,
    // promotionDiscount,
    price,
    commission,
    net,
  } = useSelector((store) => store.order);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);

    // Extract year, month, and day from both dates for comparison
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const selectedYear = selectedDateObj.getFullYear();
    const selectedMonth = selectedDateObj.getMonth();
    const selectedDay = selectedDateObj.getDate();

    // Ensure that only future dates can be selected
    if (
      selectedYear < currentYear ||
      (selectedYear === currentYear && selectedMonth < currentMonth) ||
      (selectedYear === currentYear &&
        selectedMonth === currentMonth &&
        selectedDay < currentDay)
    ) {
      alert("Please select a future date.");
    } else {
      // Valid date, update Redux state
      dispatch(setOrderDate(selectedDate));
      dispatch(setOrderTime(""));
    }
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    const currentDate = new Date();
    const selectedDateObj = new Date(orderDate + "T" + selectedTime); // Combine date and time

    // Check if the selected date is today
    if (
      selectedDateObj.getFullYear() === currentDate.getFullYear() &&
      selectedDateObj.getFullYear() === currentDate.getFullYear() &&
      selectedDateObj.getMonth() === currentDate.getMonth()
    ) {
      // If today, compare time with the current time
      const currentTime =
        currentDate.getHours() * 60 + currentDate.getMinutes(); // yields total minutes after 00:00
      const selectedTimeValue =
        parseInt(selectedTime.split(":")[0]) * 60 +
        parseInt(selectedTime.split(":")[1]);

      if (
        selectedDateObj.getDate() === currentDate.getDate() &&
        selectedTimeValue <= currentTime
      ) {
        alert("Please select a future time.");
      } else {
        // Valid time, update Redux state
        dispatch(setOrderTime(selectedTime));
      }
    } else {
      dispatch(setOrderTime(selectedTime));
    }
  };

  useEffect(() => {
    const fetchPricing = async () => {
      // this triggers the fetch pricing function
      try {
        const response = await axios.get(`/api/pricing/${truckSize}`);
        setPricing(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (truckSize) {
      fetchPricing();
    }
  }, [truckSize]);

  useEffect(() => {
    // this triggers calculateQuote function
    if (pricing) {
      dispatch(calculateQuote({ pricing: pricing }));
    }
  }, [pricing, kms, helpers, floors, orderDate, dispatch]);

  /** @type React.MutableRefObject<HTMLInputElement> */
  const pickupRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef();

  // google maps:

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: library,
  }); // The primary objective of this code is to ensure that the Google Maps API and any specified libraries are loaded before using any Google Maps-related functionality in your application.
  // Once the API and libraries are loaded, the isLoaded variable is likely used to determine if they are ready for use in your application, and you can then proceed to use the Google Maps services.
  // ...such as the Direction Service or displaying maps.
  // The libraries property appears to be a placeholder for specifying which Google Maps libraries you want to load.

  // google maps start:
  const calculateRoute = useCallback(async () => {
    // google maps start:
    if (pickup && destination) {
      // eslint-disable-next-line no-undef
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: pickup.formatted_address,
        destination: destination.formatted_address,
        // eslint-disable-next-line no-undef
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      const drivingKms = results.routes[0].legs[0].distance.value; // ...distance.text per documentation returns the value in kms (because places are in SA)
      // is is value is like this: '1,469.20 kms' which we will have to parseFloat to clean up
      // .value will therefore always return the value in meters regardless of region

      dispatch(setKms(drivingKms / 1000)); // converting from meters to kms
    }
  }, [pickup, destination, dispatch]);
  // google maps: end:

  //   useEffect(() => {
  //     if (pickup && destination) {
  //       calculateRoute();
  //     }
  //   }, [pickup, destination]);

  useEffect(() => {
    calculateRoute();
  }, [calculateRoute]);

  useEffect(() => {
    const calculatedCommission = price * 0.2;
    dispatch(setCommission(Math.ceil(calculatedCommission)));
  }, [price, dispatch]);

  useEffect(() => {
    dispatch(setNet(price - commission));
  }, [price, commission, dispatch]);

  const loadPickup = (autocomplete) => {
    pickupRef.current = autocomplete;
  };

  const loadDestination = (autocomplete) => {
    destinationRef.current = autocomplete;
  };

  // the following function gets invoked when you click a suggested place
  // not when you type
  const handlePickupPlaceSelect = () => {
    if (pickupRef.current !== null) {
      const place = pickupRef.current.getPlace();
      dispatch(
        setPickup({
          formatted_address: place.formatted_address,
          location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        })
      );
      dispatch(setPickupPlace({ formatted_address: place.formatted_address }));
    } else {
      console.log("Origin autocomplete is not yet loaded");
    }
  };

  const handleDestinationPlaceSelect = () => {
    if (destinationRef.current !== null) {
      const place = destinationRef.current.getPlace();
      dispatch(
        setDestination({
          formatted_address: place.formatted_address,
          location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        })
      );
      dispatch(
        setDestinationPlace({ formatted_address: place.formatted_address })
      );
    } else {
      console.log("Destination autocomplete is not yet loaded");
    }
  };

  const validateFields = () => {
    const errors = {};
    if (!destination) {
      errors.destination = "Destination location is required";
    }
    if (!truckSize) {
      errors.truckSize = "Truck size is required";
    }
    if (!orderDate) {
      errors.orderDate = "Order date is required";
    }
    if (!orderTime) {
      errors.orderTime = "Order Time is required";
    }

    if (
      !pickupPlace ||
      pickupPlace.formatted_address !== pickup.formatted_address
    ) {
      errors.pickup =
        "Invalid pickup location. Please enter a place or address and select from google suggestions";
    }

    if (
      !destinationPlace ||
      destinationPlace.formatted_address !== destination.formatted_address
    ) {
      errors.destination =
        "Invalid destination location. Please enter a place or address and select from google suggestions";
    }

    if (
      !pickupPlace ||
      pickupPlace.formatted_address !== pickup.formatted_address ||
      !destinationPlace ||
      destinationPlace.formatted_address !== destination.formatted_address ||
      !destination ||
      !truckSize ||
      !orderDate ||
      !orderTime
    ) {
      setOverallError("*Please complete all the required fields");
    } else {
      setOverallError("");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // returns true or false
    // this returns the keys of the errors object
    // remember each object has a key and a value
    // keys are name, cellphone, etc. If there are errors it returns false
  };

  const handleNext = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }
    navigate("/orders/pricebreakdown");
    window.scroll(0, 0);
  };

  const handleRefresh = () => {
    dispatch(setTruckSize(""));
    dispatch(
      setPickup({ formatted_address: "", location: { lat: null, lng: null } })
    );
    dispatch(
      setDestination({
        formatted_address: "",
        location: { lat: null, lng: null },
      })
    );
    dispatch(setKms(0));
    dispatch(setOrderDate(""));
    dispatch(setOrderTime(""));
    dispatch(setHelpers(0));
    dispatch(setFloors(0));
    dispatch(setBaseCost(0));
    dispatch(setDistanceCost(0));
    dispatch(setHelpersCost(0));
    dispatch(setFloorsCost(0));
    dispatch(setMidmonthDiscount(0));
    dispatch(setRepeatDiscount(0));
    dispatch(setPromotionDiscount(0));
    dispatch(setPrice(0));
    dispatch(setCommission(0));
    dispatch(setNet(0));
  };

  if (!isLoaded) {
    return console.log("loading maps script...");
  }

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6">
              <div className="title">
                <h1 className="title has-text-centered has-text-weight-bold">
                  Quote
                </h1>
              </div>

              <div className="card box">
                <p className="card-header-title blue-font-color is-size-4 is-centered">
                  Get a free instant quote
                </p>
                <div className="card-content">
                  <div className="content">
                    <ul>
                      <li className="has-text-centered has-text-danger has-text-weight-bold">
                        Please note that we are currently operational only in
                        Gauteng. We will expand to other provinces if there is
                        sufficient demand.
                      </li>
                      <li className="has-text-centered has-text-danger has-text-weight-bold">
                        We do not currently provide long distance transportation
                        (one province to another).
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="card-content">
                  {/* <div className="content"> */}
                  <form>
                    <div className="field">
                      <label className="label blue-font-color">Pick Up</label>
                      <div className="control">
                        <Autocomplete
                          onLoad={loadPickup}
                          onPlaceChanged={handlePickupPlaceSelect}
                        >
                          <input
                            type="text"
                            className={`input ${
                              errors.pickup ? "is-danger" : ""
                            }`}
                            // placeholder={pickup.formatted_address} // use this if issues arise
                            value={pickup.formatted_address} // if issues remove this
                            onChange={(e) =>
                              dispatch(
                                setPickup({
                                  formatted_address: e.target.value,
                                })
                              )
                            } // if issues remove this
                          ></input>
                        </Autocomplete>
                        {/* {pickup.formatted_address && <p>Pick Up: {pickup.formatted_address}</p>}         */}
                      </div>
                      {errors.pickup && (
                        <p className="help is-danger">{errors.pickup}</p>
                      )}
                    </div>

                    <div className="field">
                      <label className="label blue-font-color">
                        Destination
                      </label>
                      <div className="control">
                        <Autocomplete
                          onLoad={loadDestination}
                          onPlaceChanged={handleDestinationPlaceSelect}
                        >
                          <input
                            className={`input ${
                              errors.destination ? "is-danger" : ""
                            }`}
                            type="text"
                            value={destination.formatted_address} // if issues remove this
                            onChange={(e) =>
                              dispatch(
                                setDestination({
                                  formatted_address: e.target.value,
                                })
                              )
                            } // if issues remove this
                            // placeholder={destination.formatted_address} // then use this
                          ></input>
                        </Autocomplete>
                        {/* { destination.formatted_address && <p>Destination: {destination.formatted_address}</p>} */}
                      </div>
                      {errors.destination && (
                        <p className="help is-danger">{errors.destination}</p>
                      )}
                    </div>

                    {/* <fieldset disabled> */}
                    <div className={`field`}>
                      <label className="label blue-font-color">
                        Kilometers
                      </label>
                      <div className="control">
                        <input
                          className={`input ${
                            kms !== "" ? "read-only-input" : ""
                          }`} // Apply the CSS class when kms is not empty
                          // type="number"
                          value={kms}
                          // onChange={(e) => setKms(e.target.value)}
                          readOnly={true}
                          disabled
                        ></input>
                      </div>
                    </div>
                    {/* </fieldset> */}

                    {/* <div className={`field`}>
                      <label className="label blue-font-color">
                        Truck Size
                      </label>
                      <div className="field is-grouped">
                        <div className="control">
                          <div
                            className={`select ${
                              errors.truckSize ? "is-danger" : ""
                            }`}
                          >
                            <select
                              value={truckSize}
                              onChange={(e) =>
                                dispatch(setTruckSize(e.target.value))
                              }
                            >
                              <option value="pleaseSelect">
                                Please select
                              </option>
                              <option value="0.5ton">mini van</option>
                              <option value="1ton">1 ton</option>
                              <option value="1.5ton">1.5 ton</option>
                              <option value="3ton">3 ton</option>
                              <option value="8ton">8 ton</option>
                            </select>
                          </div>
                        </div>
                        <div className="control">
                          <p>
                            {" "}
                            click
                            <NavLink
                              to="/trucksInfo"
                              className="has-text-weight-bold firm-color"
                              // onClick={() => {
                              //   // to ensure you end up at the top of the page when going to link
                              //   window.scroll(0, 0);
                              // }}
                            >
                              {" "}
                              here
                            </NavLink>{" "}
                          </p>
                        </div>
                      </div>
                      {errors.truckSize && (
                        <p className="help is-danger">{errors.truckSize}</p>
                      )}
                    </div> */}

                    <div className="field">
                      <label className="label blue-font-color">
                        Truck Size
                      </label>
                      <div className="field is-grouped">
                        <div className="control">
                          <div
                            className={`select ${
                              errors.truckSize ? "is-danger" : ""
                            }`}
                          >
                            <div className="select">
                              <select defaultValue="pleaseSelect" disabled>
                                <option value="pleaseSelect" disabled>
                                  {truckSize ? truckSize : "Please select"}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="control">
                          <p>
                            click{" "}
                            <NavLink
                              className="has-text-weight-bold firm-color"
                              to="/trucksInfo"
                              // onClick={() => {
                              //   // to ensure you end up at the top of the page when going to link
                              //   window.scroll(0, 0);
                              // }}
                            >
                              here
                            </NavLink>{" "}
                          </p>
                        </div>
                      </div>
                      {errors.truckSize && (
                        <p className="help is-danger">{errors.truckSize}</p>
                      )}
                    </div>

                    <div className={`field`}>
                      <label className="label blue-font-color">
                        Date of Move
                      </label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.orderDate ? "is-danger" : ""
                          }`}
                          type="date"
                          value={orderDate}
                          // onChange={(e) => dispatch(setOrderDate(e.target.value))}
                          onChange={handleDateChange}
                        ></input>
                      </div>
                      {errors.orderDate && (
                        <p className="help is-danger">{errors.orderDate}</p>
                      )}
                    </div>

                    <div className={`field`}>
                      <label className="label blue-font-color">
                        Time of Move
                      </label>
                      <div className="control">
                        <input
                          className={`input ${
                            errors.orderTime ? "is-danger" : ""
                          }`}
                          type="time"
                          value={orderTime}
                          // onChange={(e) => dispatch(setOrderTime(e.target.value))}
                          onChange={handleTimeChange}
                        ></input>
                      </div>
                      {errors.orderTime && (
                        <p className="help is-danger">{errors.orderTime}</p>
                      )}
                    </div>

                    <div className={`field`}>
                      <label className="label blue-font-color">Helpers</label>
                      <div className="control">
                        <div className="select">
                          <select
                            value={helpers}
                            onChange={(e) =>
                              dispatch(setHelpers(e.target.value))
                            }
                          >
                            {/* <option value="pleaseSelect">Please select</option> */}
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className={`field`}>
                      <label className="label blue-font-color">Floors</label>
                      <div className="control">
                        <div className="select">
                          <select
                            value={floors}
                            onChange={(e) =>
                              dispatch(setFloors(e.target.value))
                            }
                          >
                            {/* <option value="pleaseSelect">Please select</option> */}
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* <div className="field">
                      <div className="control">
                          <button onClick={allCalcs} type="button" className={`button is-success is-fullwidth`}>Calculate quote</button>
                      </div>
                  </div> */}

                    <div className={`field is-hidden`}>
                      <label className="label blue-font-color">Price</label>
                      <div className="control">
                        <input
                          className={`input ${
                            price !== "" ? "read-only-input" : ""
                          }`}
                          type="number"
                          value={price}
                          readOnly={true}
                        ></input>
                      </div>
                    </div>

                    {/* {price > 0 && ( // if price and price does not equal to zero. because initial state is 0
                      <div className="subtitle has-text-weight-bold">
                        The move will only cost{" "}
                        <span className="has-text-success has-text-weight-bold">
                          R{price}
                        </span>
                      </div>
                    )} */}

                    <div className={`field is-hidden`}>
                      <label className="label blue-font-color">
                        Commission
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type="number"
                          value={commission}
                          readOnly={true}
                        ></input>
                      </div>
                    </div>

                    <div className={`field is-hidden`}>
                      <label className="label blue-font-color">Net</label>
                      <div className="control">
                        <input
                          className="input"
                          type="number"
                          value={net}
                          readOnly={true}
                        ></input>
                      </div>
                    </div>

                    {/* <div className="field">
                      <div className="control">
                        <p>
                          If you are happy with the quote please click the
                          'Next' button below
                        </p>
                      </div>
                    </div> */}

                    {overallError && (
                      <div>
                        <p className="has-text-centered has-text-danger">
                          {overallError}
                        </p>
                        <br></br>
                      </div>
                    )}

                    <div className="field">
                      <div className="control">
                        <div className="buttons is-centered">
                          <button
                            type="button"
                            onClick={handleNext}
                            className={`button blue-background-color has-text-white is-rounded`}
                          >
                            Calculate Quote
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <p>
                          Click the 'Refresh Quote' button below to reset the
                          fields
                        </p>
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <div className="buttons is-centered">
                          <button
                            type="button"
                            onClick={handleRefresh}
                            className={`button blue-background-color has-text-white is-rounded`}
                          >
                            Refresh Quote
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* {price > 0 && (
                      <div>
                        <h3>Quote Summary</h3>
                        <p>Base Cost: {baseCost}</p>
                        <p>Distance Cost: {distanceCost}</p>
                        <p>Helpers Cost: {helpersCost}</p>
                        <p>Floors Cost: {floorsCost}</p>
                        <p>Midmonth Discount: {midmonthDiscount}</p>
                        <p>Promotion discount: {promotionDiscount}</p>
                        <p>Repeat Discount: {repeatDiscount}</p>
                        <p>Total Price: {price}</p>
                      </div>
                    )} */}
                  </form>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QuoteComp;
