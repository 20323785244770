import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../features/tokenSlice";

const UserList = () => {
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.tokenReducer);

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]); // Add dispatch as a dependency

  const getUsers = useCallback(async () => {
    try {
      const response = await axios.get("/api/users/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error.response);
    }
  }, [token]); // Add token as a dependency

  useEffect(() => {
    getUsers(); // Call getUsers immediately after defining it
  }, [getUsers, token]);
  // not necessary to add getUsers as a dependency
  // this is because getUsers does not depend on this dependency to execute
  // this is defined in the useCallback function

  const deleteUser = async (userId) => {
    await axios.delete(`api/users/delete/${userId}`);
    getUsers();
  };

  return (
    <div>
      <h1 className="title">Administrators</h1>
      <h2 className="subtitle">List of Administrators</h2>

      <Link
        to="/users/register"
        className="button is-primary mb-2"
        onClick={() => {
          // to ensure you end up at the top of the page when going to link
          window.scroll(0, 0);
        }}
      >
        Add New Administrator
      </Link>

      <div className="table-container">
        {" "}
        {/* Apply responsive container */}
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>Id</th>
              <th>Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Cellphone</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.email}</td>
                <td>{user.cellphone}</td>
                <td>{user.role}</td>
                <td>{user.status}</td>
                <td>
                  <Link
                    to={`/users/update/${user.id}`}
                    className="button is-small is-info"
                    onClick={() => {
                      // to ensure you end up at the top of the page when going to link
                      window.scroll(0, 0);
                    }}
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => deleteUser(user.id)}
                    className="button is-small is-danger"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
