import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/general/Home";
import LoginUser from "./pages/users/LoginUser";
import LoginClient from "./pages/clients/LoginClients";
import Clients from "./pages/clients/Clients";
import ClientEmailVer from "./pages/clients/ClientEmailVer";
import AddClient from "./pages/clients/AddClient";
import UpdateClient from "./pages/clients/UpdateClient";
import ForgotClientPass from "./pages/clients/ForgotClientPass";
import UpdateClientPass from "./pages/clients/UpdateClientPass";
import Users from "./pages/users/Users";
import AddUser from "./pages/users/AddUser";
import Owners from "./pages/owners/Owners";
import Drivers from "./pages/drivers/Drivers";
import AddDriver from "./pages/drivers/AddDriver";
import DriverEmailVer from "./pages/drivers/DriverEmailVer";
import AddDriverOnly from "./pages/drivers/AddDriverOnly";
import UpdateDriver from "./pages/drivers/UpdateDriver";
import Vehicles from "./pages/vehicles/Vehicles";
import AddVehicleOnly from "./pages/vehicles/AddVehicleOnly";
import UpdateVehicle from "./pages/vehicles/UpdateVehicle";
import Orders from "./pages/orders/OrderList";
import Dashboard from "./pages/general/Dashboard";
import TsCs from "./pages/general/TsCs";
import PrivacyPolicy from "./pages/general/PrivacyPolicy";
import Services from "./pages/general/Services";
import About from "./pages/general/About";
import TruckSizes from "./pages/general/TruckSizes";
import Quote from "./pages/orders/Quote";
import OrderTerms from "./pages/orders/OrderTerms";
import Checkout from "./pages/orders/Checkout";
import PriceBreakdown from "./pages/orders/PriceBreakdown";
import OrderDetails from "./pages/orders/OrderDetails";
import Pricing from "./pages/pricing/pricing";
import AddPricing from "./pages/pricing/AddPricing";
import EditPricing from "./pages/pricing/EditPricing";
import Contact from "./pages/general/Contact";
import Return from "./pages/general/Return";
import Cancel from "./pages/general/Cancel";
import Otps from "./pages/otps/Otps";
import Test from "./pages/Test";
import Test3 from "./pages/drivers/Test3";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/test" element={<Test></Test>}></Route>
          <Route path={"/"} element={<Home></Home>}></Route>
          <Route path="/users/login" element={<LoginUser></LoginUser>}></Route>
          <Route
            path="/clients/login"
            element={<LoginClient></LoginClient>}
          ></Route>
          <Route path="/clients/all" element={<Clients></Clients>}></Route>
          <Route
            path="/clients/register"
            element={<ClientEmailVer></ClientEmailVer>}
          ></Route>
          <Route
            path="/clients/register/form"
            element={<AddClient></AddClient>}
          ></Route>
          <Route
            path="/clients/update/:id"
            element={<UpdateClient></UpdateClient>}
          ></Route>
          <Route
            path="/clients/forgotpassword"
            element={<ForgotClientPass></ForgotClientPass>}
          ></Route>
          <Route
            path="/clients/updatepassword"
            element={<UpdateClientPass></UpdateClientPass>}
          ></Route>
          <Route path="/users/all" element={<Users></Users>}></Route>
          <Route path="/users/register" element={<AddUser></AddUser>}></Route>
          <Route path="/owners/all" element={<Owners></Owners>}></Route>
          <Route path="/drivers/all" element={<Drivers></Drivers>}></Route>
          <Route
            path="/drivers/register/form"
            element={<AddDriver></AddDriver>}
          ></Route>
          <Route
            path="/drivers/register"
            element={<DriverEmailVer></DriverEmailVer>}
          ></Route>
          <Route
            path="/drivers/register/driveronly"
            element={<AddDriverOnly></AddDriverOnly>}
          ></Route>
          <Route
            path="/drivers/update/:id"
            element={<UpdateDriver></UpdateDriver>}
          ></Route>
          <Route path="/vehicles/all" element={<Vehicles></Vehicles>}></Route>
          <Route
            path="/vehicles/register/vehicleonly"
            element={<AddVehicleOnly></AddVehicleOnly>}
          ></Route>
          <Route
            path="/vehicles/update/:id"
            element={<UpdateVehicle></UpdateVehicle>}
          ></Route>
          <Route path="/orders/all" element={<Orders></Orders>}></Route>
          <Route path="/pricing" element={<Pricing></Pricing>}></Route>
          <Route
            path="/pricing/create"
            element={<AddPricing></AddPricing>}
          ></Route>
          <Route
            path="/pricing/:truckSize"
            element={<EditPricing></EditPricing>}
          ></Route>
          <Route path="/dashboard" element={<Dashboard></Dashboard>}></Route>
          <Route path="/terms" element={<TsCs></TsCs>}></Route>
          <Route
            path="/privacy"
            element={<PrivacyPolicy></PrivacyPolicy>}
          ></Route>
          <Route path="/services" element={<Services></Services>}></Route>
          <Route path="/trucksinfo" element={<TruckSizes></TruckSizes>}></Route>
          <Route path="/quote" element={<Quote></Quote>}></Route>
          <Route
            path="/orders/terms"
            element={<OrderTerms></OrderTerms>}
          ></Route>
          <Route
            path="/orders/checkout"
            element={<Checkout></Checkout>}
          ></Route>
          <Route
            path="/orders/details/:id"
            element={<OrderDetails></OrderDetails>}
          ></Route>
          <Route
            path="/orders/pricebreakdown"
            element={<PriceBreakdown></PriceBreakdown>}
          ></Route>
          <Route path="/about" element={<About></About>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path="/return" element={<Return></Return>}></Route>
          <Route path="/cancel" element={<Cancel></Cancel>}></Route>
          <Route path="/otps/all" element={<Otps></Otps>}></Route>
          <Route path="/test3" element={<Test3></Test3>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
