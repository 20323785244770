import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import tokenReducer from "../features/tokenSlice";
import regClientReducer from "../features/clientRegSlice";
import regDriverReducer from "../features/driverRegSlice";
import regVehicleOnlyReducer from "../features/vehicleOnlyRegSlice";
import orderReducer from "../features/orderSlice";
import testReducer from "../features/testSlice";
import generalReducer from "../features/generalSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    tokenReducer: tokenReducer,
    regClient: regClientReducer,
    regDriver: regDriverReducer,
    regVehicleOnly: regVehicleOnlyReducer,
    order: orderReducer,
    testRed: testReducer,
    generalReducer: generalReducer,
  },
});
