import React, { useEffect } from "react";
import AddUserForm from "../../components/users/AddUserForm";
import Layout from "../general/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, party } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/clients/login");
    } else if (
      party &&
      (party.type !== "user" ||
        (party.type === "user" && party.role !== "admin"))
    ) {
      // note (1)
      navigate("/dashboard");
    }
  }, [party, navigate, isError]);

  return (
    <Layout>
      <AddUserForm></AddUserForm>
    </Layout>
  );
};

export default AddUser;
